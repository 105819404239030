
import { 
	UserFilled, 
	Service, 
	Tickets,
	Ticket,
	DataAnalysis, 
	Notebook, 
	TurnOff, 
	PieChart, 
	Setting, 
	Tools, 
	Refresh,
	Files,
	Document,
	DocumentAdd,
	List,
	SetUp,
	Collection,
	OfficeBuilding,
	Operation,
	DataLine,
	User,
	Management,
	DataBoard,
	Edit,
	Close,
	WarningFilled,
	Check,
	UploadFilled,
	Failed,
	CloseBold,
	InfoFilled,
	Promotion,
	Money,
	DocumentChecked,
	Plus,
	Download,
	Paperclip,
	Stamp,
	EditPen,
	Filter,
	Switch,
	Calendar,
	Finished,
	QuestionFilled,
	View,
	Delete,
	Search,
	ZoomIn,
	Location,
} from '@element-plus/icons-vue'
const registerGlobalComponents = async app => {
	app.component('UserFilled', UserFilled)
	app.component('Service', Service)
	app.component('Tickets', Tickets)
	app.component('Ticket', Ticket)
	app.component('DataAnalysis', DataAnalysis)
	app.component('Notebook', Notebook)
	app.component('TurnOff', TurnOff)
	app.component('PieChart', PieChart)
	app.component('Setting', Setting)
	app.component('Tools', Tools)
	app.component('Refresh', Refresh)
	app.component('Files', Files)
	app.component('Document', Document)
	app.component('DocumentAdd', DocumentAdd)
	app.component('List', List)
	app.component('SetUp', SetUp)
	app.component('Collection', Collection)
	app.component('OfficeBuilding', OfficeBuilding)
	app.component('Operation', Operation)
	app.component('DataLine', DataLine)
	app.component('User', User)
	app.component('Management', Management)
	app.component('DataBoard', DataBoard)
	app.component('Edit', Edit)
	app.component('WarningFilled', WarningFilled)
	app.component('Close', Close)
	app.component('Check', Check)
	app.component('UploadFilled', UploadFilled)
	app.component('Failed', Failed)
	app.component('CloseBold', CloseBold)
	app.component('InfoFilled', InfoFilled)
	app.component('Promotion', Promotion)
	app.component('Money', Money)
	app.component('DocumentChecked', DocumentChecked)
	app.component('Plus', Plus)
	app.component('Download', Download)
	app.component('Paperclip', Paperclip)
	app.component('Stamp', Stamp)
	app.component('EditPen', EditPen)
	app.component('Filter', Filter)
	app.component('Switch', Switch)
	app.component('Calendar', Calendar)
	app.component('Finished', Finished)
	app.component('QuestionFilled', QuestionFilled)
	app.component('View', View)
	app.component('Delete', Delete)
	app.component('Search', Search)
	app.component('ZoomIn', ZoomIn)
	app.component('Location', Location)
}

export default registerGlobalComponents