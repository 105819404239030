/*
	Cria a ação (que retorna uma Promise)
*/
const clearAction = ({ moduleName }) => 
	(store, { initialState, uid } = {} ) => 
		new Promise( async( resolve ) => {
			let { commit } = store
			commit(`clear${moduleName}`, { initialState, uid })
		})

/*
	Cria a ação com o operationId e moduleName
	retorna um objeto com a chave certa para a action
	do vuex, proto para um merge: actions: { ...estaAction }
*/
export default ( options ) => {
	let { moduleName } = options
	return { [`clear${moduleName}`]: clearAction( options ) }
}