import logOperationId from "../logger"
/*
	Atualiza o resultado da operação
*/
const createMutation = ({ operationId }) => (state, { uid, result, error } = {}) => {
	state.uids[uid] = {
		...(state.uids[uid] || {}),
		result,
		error
	};
	// logOperationId(`update${operationId}`, { uid, result, error });
}

/*
	Cria a mutação com o operationId e moduleName
	retorna um objeto com a chave certa para a mutation
	do vuex, proto para um merge: mutation: { ...estaMutation }
*/
export default ({ operationId, moduleName }) => {
	return {
	  [`update${operationId}`]: createMutation({ operationId, moduleName })
	};
  }
  