/*
	Nos informa que a app está pronta para uso
	swagger fica ready após inicializar as apis
	oidc fica ready quando o temos um user logado
*/
export default state => {

	/*
		Coleta o uid do tipo de solicitacao escolhido
	*/
	let uid = state.swagger.novaSolicitacao.tipoSolicitacaoEscolhido
	if(!uid) return

	/*
		Coleta os tipos de solicitacao disponíveis
	*/
	let tiposSolicitacao = state.swagger.listagemTiposSolicitacao.result
	if(!tiposSolicitacao) return

	/*
		Retorna o objeto do tipo de solicitacao escolhido, caso exista
	*/
	return tiposSolicitacao.find( tipo => tipo.tipoSolicitacaoUid === uid)

}