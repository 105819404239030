import { ElNotification } from "element-plus"
import SwaggerRequest from "../plugins/swagger/SwaggerRequest"

/*
	Busca o mapa processual da solicitação
	que está sendo criada (por id)
	e sua estrutura de meta dados
*/
export default async (store, { id, uid }) => {{
	let { state } = store
	/*
		__TODO__
		avaliar se devemos trocar para getter isAppReady
	*/
	if(state.swagger.ready){
		/*
			Precisamos do id
		*/
		if(!id) return 

		/*
			Precisamos do uid
		*/
		if(!uid) return 

		/*
			Tenta carregar os dados
		*/
		try {
			
			let response = await SwaggerRequest({
				// __TODO__ Corrigir esse erro de nome no id da operação no swagger remoto ('desvicular...')
				// Algo errado ao desvincular... Requisição parece correta
				operationId: "DesvincularInstrucaoComTipoSolicitacao",
				parameters: { id, uid },
			})
			/*
				Recebemos os dados
			*/
			if(response.ok){
				ElNotification({
					title: "Instrução desvinculada com sucesso",
					type: "success"
				})
				return 
			}
			console.log("Problema com DesvincularInstrucaoComTipoSolicitacao")
			console.log(response)
			/*
				Deu algo errado, apenas informa
			*/
			ElNotification({
				title: "Problema ao desvincular a instrução",
				type: "warning",
				message: "Tente novamente mais tarde"
			})
		}catch(error){
			console.log("Erro DesvincularInstrucaoComTipoSolicitacao", error)
			ElNotification({
				title: "Problema ao desvincular a instrução",
				type: "warning",
				message: "Tente novamente mais tarde"
			})
		}
	}
}}
	

