/*
	Gerenciamento de Roles client side
*/
import { computed } from "@vue/runtime-dom"

/*
	Para reunião
	Usuário Advogado X (99999999999) não pode logar com senha 123
	Como identificar usuário externo? Sem roles?
	Anexos de despachos não são resgatados na leitura do Atendimento
	Pendências são registradas, mas não tem como resgatá-las também
	Anexos de pendência não tem endpoint próprio
*/

/*
	Roles conhecidas
*/
export const ROLES = {
	ADMIN:				"admin",
	GODMODE:			"godmode",
	CHEFE_ATENDENTE:	"chefe-atendente",
	ANALISTA_ATENDENTE:	"analista-atendente",
	CHEFE_MINUTA:		"chefe-minuta",
	ANALISTA_MINUTA:	"analista-minuta",
	CHEFE_LAVRATURA:	"chefe-lavratura",
	ANALISTA_LAVRATURA:	"analista-lavratura",
	CHEFE_PAGAMENTO:	"chefe-pagamento",
	ANALISTA_PAGAMENTO:	"analista-pagamento",
	ANALISTA:	"analista",
	CHEFE:	"chefe",
	ADVOGADO:			"advogado",
	PARTE_INTERESSADA:	"parte-interessada",
}

/*
	Roles de usuários internos
*/
export const INTERNAL_ROLES = [
	ROLES.ADMIN,
	ROLES.GODMODE,
	ROLES.CHEFE,
	ROLES.ANALISTA,
	ROLES.CHEFE_ATENDENTE,
	ROLES.ANALISTA_ATENDENTE,
	ROLES.CHEFE_MINUTA,
	ROLES.ANALISTA_MINUTA,
	ROLES.CHEFE_LAVRATURA,
	ROLES.ANALISTA_LAVRATURA,
	ROLES.CHEFE_PAGAMENTO,
	ROLES.ANALISTA_PAGAMENTO,
]

export const EXTERNAL_ROLES = [
	ROLES.PARTE_INTERESSADA,
	ROLES.ADVOGADO,
]

export const SPECIAL_ROLES = [
	ROLES.ADMIN,
	ROLES.GODMODE,
]

export const ALL_ROLES = INTERNAL_ROLES.concat(EXTERNAL_ROLES).concat(SPECIAL_ROLES);

/*
	hasRole (curry)
*/
const curryHasRole = userRoles => role => userRoles.includes(role) || userRoles.some(inner => inner.startsWith(role) || role.startsWith(inner))

/*
	authorizeByRoles (curry)
*/
const curryAuthorizeByRoles = userRoles => (roleOrRoles=[]) => {

	/*
		Garantindo que será uma array
	*/
	let roles = [].concat(userRoles)

	/*
		É autorizado se for admin ou se conter ao menos uma Role
		da lista de Roles passada para autorização
	*/
	const isAuthorized = computed(() => {
        const isAdminOrGodMode = SPECIAL_ROLES.some(role => userRoles.includes(role));
        const hasRole = roles.some(role => userRoles.includes(role) || userRoles.some(inner => inner.startsWith(role) || role.startsWith(inner)));
      
        return isAdminOrGodMode || hasRole;
      });
	
	/*
		Retornando apenas o valor da propriedade computada
		__TODO__ verificar se isso é boa prática
	*/
	return isAuthorized.value
}

/*
	Nosso Hook de roles
*/
const useRoles = (oidc) => {
	// const store = useStore();
	/*
		Roles segundo nosso perfil OIDC
		Garantindo que será sempre array, 
		pois a propriedade pode ser recebida
		apenas como string também
	*/
	const roles = computed(() => [].concat(oidc?.user?.profile?.role || []));

	/*
		Verifica se temos uma Role
	*/
	const hasRole = curryHasRole(roles.value)

	/*
		Cria uma propriedade reativa de autorização
		que muda para true ou false de acordo com
		a existência das roles especificadas ou a
		existência de roles sempre autorizadas

		ex: 
		let isAuthorized = authorizeByRoles('analista-atendendimento')

	*/
	const authorizeByRoles = curryAuthorizeByRoles(roles.value) 

	/*
		Identificação de usuário externo (sem role nenhuma)
	*/
	const isExternalUser = computed(() => roles.value.length === 0 || authorizeByRoles(EXTERNAL_ROLES))

	/*
		Identificação de colaborador
	*/
	const isInternalUser = computed(() => authorizeByRoles(INTERNAL_ROLES) )

	/*
		API
	*/
	return {
		roles,
		hasRole,
		authorizeByRoles,
		isExternalUser,
		isInternalUser
	}
}

export { useRoles }
