import { ElNotification } from "element-plus"
import SwaggerRequest from "../plugins/swagger/SwaggerRequest"
import FileSaver from 'file-saver'

export default async (store, options = {}) => {{
	let { state } = store
	/*
		__TODO__
		avaliar se devemos trocar para getter isAppReady
	*/
	if(state.swagger.ready){
		/*
			Id
		*/
		let {
			uid,
			name,
		} = options

		/*
			Parametros
		*/
		let parameters = { uid }

		/*
			Tenta carregar os dados
		*/
		try {

			let response = await SwaggerRequest({
				operationId: "BaixarAnexo",
				parameters
			})

			/*
				Recebemos os dados
			*/
			if(response.ok){
				// let file = new File([response.data], name)
				// console.log(name, file)
				// let localDownload = window.URL.createObjectURL(file);
				// window.location.assign(localDownload);
				FileSaver.saveAs(response.data, name)
				return
			}
			console.log("Problema com BaixarAnexo")
			console.log(response)
			/*
				Deu algo errado, apenas informa
			*/
			ElNotification({
				title: "Problema ao baixar o anexo",
				type: "warning",
				message: "Tente novamente mais tarde"
			})
		}catch(error){
			console.log("Erro BaixarAnexo", error)
			/*
				Deu algo errado, apenas informa
			*/
			ElNotification({
				title: "Problema ao baixar o anexo",
				type: "warning",
				message: "Tente novamente mais tarde"
			})
		}
	}
}}
	

