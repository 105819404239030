import { Alert } from "../../../plugins/notification";
import router from "../../../router";
import SwaggerRequest from "../../plugins/swagger/SwaggerRequest";
import { oidc } from "../../../store/plugins/oidc/index";
import { isValidJSON } from "../../../plugins/props";

const executeSwaggerRequest = async (
    operationId,
    parameters,
    body,
    successRoute,
    successRouteProp,
    successAlert,
    commit
) => {
    let response = await SwaggerRequest({
        operationId,
        parameters,
        body,
    });

    if (response.ok) {
        let result = '';
        
        if (isValidJSON(response.data)) {
            result = JSON.parse(response.data);
        } else if(typeof response.data === 'Blob' && response.data?.size === 0) {
            result = response.ok;
        }

        if (result === '') {
            result = true;
        }

        commit(`update${operationId}`, { result });

        if (successRoute) {
            let routeProp = successRouteProp ? result[successRouteProp] : "";
            router.push(successRoute + routeProp);
        }
        Alert({ message: successAlert, type: "success" });
        return { result, parameters, body };
    }

    let error = new Error("Problema com a requisição");
    console.log(`Erro com a operação ${operationId}`, response);
    return { error, response, parameters, body };
};

/*
	Cria a ação (que retorna uma Promise)
*/
const createAction =
    ({ operationId, parametersBuilder, bodyBuilder, successRoute, successRouteProp, successAlert }) =>
    (store, options = {}) =>
        new Promise(async (resolve) => {
            let { commit, rootState } = store;
            /*
				__TODO__
				avaliar se devemos trocar para getter isAppReady
			*/
            if (rootState.swagger.ready) {
                /*
					Muda o loading para true
				*/
                commit(`isLoading${operationId}`);
				
				 let oidcUserManager = rootState.oidcUserManager

				 if (!oidcUserManager) {
				   oidcUserManager = await oidc(store)
				   commit('oidcUserManager', oidcUserManager)
				 }

                /*
					Parâmetros
				*/
                let parameters = parametersBuilder({ store, options });

                let body = bodyBuilder({ store, options });

                try {
                    let { result, error, response } = await executeSwaggerRequest(
                        operationId,
                        parameters,
                        body,
                        successRoute,
                        successRouteProp,
                        successAlert,
                        commit
                    );

                    if (!error) {
                        return resolve({ result, parameters, body });
                    }

                    console.log(`Erro com a operação ${operationId}`, response);
                    return resolve({ error, response, parameters, body });
                } catch (error) {
                    console.log(`Erro ao carregar ${operationId}`, error);

                    commit(`update${operationId}`, { error });

                    if (error && error.hasOwnProperty("status") && error.status === 401) {
                        console.log("Token expirado. Tentando renovar o token...");
                        try {
							await oidcUserManager.signinSilent();

                            try {
                                let { result, error, response } = await executeSwaggerRequest(
                                    operationId,
                                    parameters,
                                    body,
                                    successRoute,
                                    successRouteProp,
                                    successAlert,
                                    commit
                                );

                                if (!error) {
                                    return resolve({ result, parameters, body });
                                }

                                commit(`update${operationId}`, { error });

                                console.log(`Erro com a operação ${operationId}`, response);
                                return resolve({ error, response, parameters, body });
                            } catch (error) {
                                console.error("Erro ao executar a operação após a renovação do token", error);

                                commit(`update${operationId}`, { error });

								Alert({
									message: "Sua sessão expirou. Por favor, faça o login novamente.",
									type: "warning",
								});
								setTimeout(() => {
									store?.dispatch("oidcLogout");
								}, 5000);
                            }
                        } catch (error) {
                            console.error("Falha ao renovar o token", error);

                            commit(`update${operationId}`, { error });
                            
                            Alert({
                                message: "Sua sessão expirou. Por favor, faça o login novamente.",
                                type: "warning",
                            });
                            setTimeout(() => {
                                store?.dispatch("oidcLogout");
                            }, 5000);
                        }
                    }
                    return resolve({ error, parameters, body });
                } finally {
                    commit(`isLoading${operationId}`, { loading: false });
                }
            }
        });

/*
	Cria a ação com o operationId e moduleName
	retorna um objeto com a chave certa para a action
	do vuex, proto para um merge: actions: { ...estaAction }
*/
export default (options) => {
    let { moduleName } = options;
    return { [moduleName]: createAction(options) };
};
