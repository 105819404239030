/*
	Exibe o Popper Global
*/
export default (state, { component, props, virtualElement, offsetX=0, offsetY=0 }) => {
	state.Popper.show 			= true
	state.Popper.component 		= component
	state.Popper.props 			= props
	state.Popper.virtualElement	= virtualElement
	state.Popper.offsetX		= offsetX
	state.Popper.offsetY		= offsetY
}
	
