import { oidc } from "../plugins/oidc"


export default async () => {
	try {
		let userManager = await oidc()
		await userManager.signoutRedirect()
		console.log("Tudo ok com o oidcLogout_signOutRedirect")
	}catch(error){
		console.log("Problema ao tentar o oidcLogout_signOutRedirect", error)
	}
	
}
