import { isValidJSON } from "../../../plugins/props"
import SwaggerRequest from "../../plugins/swagger/SwaggerRequest"

/*
	Guardando as promises por uid,
	Podemos devolver a mesma promise
	em cenários de múltiplas requisições
	ou de preferência de uso do cache local
*/
let promises = {}

/*
	Cria a ação
	normalmente options = { uid: string }
	considere que options trará os parâmetros
	Veja no swagger ui qual parâmetro deve ir 
	no url e passe-o em options
*/
const createAction = ({ operationId }) => 
	(store, options = {}) => {

		/*
			Coletando as opções dessa operação
		*/
		let { parameters, preffersLocal = false } = options

		/*
			FIX temporário para garantir que usaremos
			uid em vez de id internamente
		*/
		let uid = parameters.uid || parameters.id

		/*
			Sem uid não tem como prosseguir
		*/
		if(!uid) return

		/*
			Se prefere um state previamente coletado,
			verifica se temos um e apenas o devolvemos
		*/
		if( preffersLocal && promises[operationId]?.[uid] )
			return promises[operationId]?.[uid]
		
		/*
			Gerando uma nova promise que nunca rejeita
		*/
		let promise = new Promise( async resolve => {

			/*
				Guardando no state local por uid
			*/
			
			let { commit, rootState } = store
			/*
				__TODO__
				avaliar se devemos trocar para getter isAppReady
			*/
			if(rootState.swagger.ready){

				/*
					Muda o loading para true
				*/
				commit(`isLoading${operationId}`, { uid })

				/*
					Tenta executar a operação
				*/
				try {
					let response = await SwaggerRequest({
						operationId,
						parameters
					});

					let result = undefined;

					try {
						if (isValidJSON(response.data)) {
							result = JSON.parse(response.data);
						} else {
							result = {
								ok: response?.ok,
								data: response?.data
							};
						}	
					  } catch (error) {
						console.log(`response.data não é um objeto JSON válido. OperationId: ${operationId} - Result: ${response}`, error);
						
						result = {
							ok: response?.ok,
							data: response?.data
						};
					  }
					
					/*
						Comitando o resultado
					*/
					commit(`update${operationId}`, { uid, result })

					/*
						Resolvendo a Promise
					*/
					return resolve({ result, parameters })
				}catch(error){
					console.log(`Erro ao carregar ${operationId}`, error)

					/*
						Comitando o resultado
					*/
					commit(`update${operationId}`, { uid, error })

					/*
						Resolvendo a Promise com o Erro
					*/
					return resolve({ parameters, error })
				}finally{
					/*
						Independente se deu certo ou não,
						não estamos mais carregando
					*/
					commit(`isLoading${operationId}`,  { uid, loading:false } )
				}
			}
		})

		/*
			Guardando a promise pelo uid
		*/
		promises[operationId] 		??= {}
		promises[operationId][uid] 	= promise

		/*
			Retornando essa promise
		*/
		return promise

	}

/*
	Cria a ação com o operationId e moduleName
	retorna um objeto com a chave certa para a action
	do vuex, proto para um merge: actions: { ...estaAction }
*/
export default ({ operationId, moduleName }) => ({
	[operationId]: createAction({ operationId, moduleName })
})