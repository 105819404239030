import SwaggerRequest from "../plugins/swagger/SwaggerRequest"

export default async (store) => {{
	let { state, commit } = store
	/*
		__TODO__
		avaliar se devemos trocar para getter isAppReady
	*/
	if(state.swagger.ready){
		/*
			Muda o loading para true
		*/
		commit("isLoadingPesquisarTipoSolicitacao")
		/*
			Parâmetros vem do state
		*/
		let { parameters } = state.swagger.tiposDeSolicitacao
		/*
			Tenta carregar os dados
		*/
		try {
			let response = await SwaggerRequest({
				operationId: "PesquisarTipoSolicitacao",
				parameters
			})

			let result = JSON.parse(response.data)
			commit("updatePesquisarTipoSolicitacao", { result })
		}catch(error){
			console.log("Erro ao buscar os tipos de solicitação", error)
		}finally{
			/*
				Independente se deu certo ou não,
				não estamos mais carregando
			*/
			commit("isLoadingPesquisarTipoSolicitacao", false)
		}
	}
}}
	