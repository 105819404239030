
import sitemap from "."
// import { useRoles } from "../plugins/roles"
import { useRoles } from "../plugins/roles/indexV2"

/*
	Recursivamente gera todos os itens de menu
	do nosso site usando nosso sitemap
*/
const siteMapMenu = ( currentViews = sitemap, prefix, oidc) => {

	let index = 0

	const { authorizeByRoles, hasRole } = useRoles(oidc)

	const computedHasRole = hasRole("admin") || hasRole("godmode");
	
	const menu = currentViews.map( ({ icon, views, hideFromMenu, title, path, external, allowedRoles }) => {
		if(!hideFromMenu){
			index = index+1
			let indexPrefix = String(prefix ? prefix + "-" +index : index)
			let show = allowedRoles
				? authorizeByRoles(allowedRoles)
				: false
			let menuItem = {
				path, title, icon, indexPrefix, external, allowedRoles, show, computedHasRole
			}
			if(views) 
				menuItem.submenu = siteMapMenu(views, indexPrefix, oidc)

			return menuItem
		}
	}).filter(Boolean)

	return menu
}

export default siteMapMenu