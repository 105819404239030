import SwaggerRequest from "../../plugins/swagger/SwaggerRequest"

/*
	Cria a ação vinculada à operação de listagem
*/

/*
	Cria a ação
*/
const createAction = ({ operationId }) => async (store, options = {}) => {{
	let { commit, rootState } = store
	/*
		__TODO__
		avaliar se devemos trocar para getter isAppReady
	*/
	if(rootState.swagger.ready){
		/*
			Muda o loading para true
		*/
		commit(`isLoading${operationId}`)

		/*
			Parâmetros vem do state
		*/
		let { parameters } = options

		/*
			Tenta carregar os dados
		*/
		try {
			let response = await SwaggerRequest({
				operationId,
				parameters
			})
			let result = JSON.parse(response.data)
			commit(`update${operationId}`, { result })
		}catch(error){
			console.log(`Erro ao buscar ${operationId}`, error)
			commit(`update${operationId}`, { error })
		}finally{
			/*
				Independente se deu certo ou não,
				não estamos mais carregando
			*/
			commit(`isLoading${operationId}`, false)
		}
	}
}}

/*
	Cria a ação com o operationId e moduleName
	retorna um objeto com a chave certa para a action
	do vuex, proto para um merge: actions: { ...estaAction }
*/
export default ({ operationId, moduleName }) => ({
	[operationId]: createAction({ operationId, moduleName })
})