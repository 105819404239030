/*
	Cria a mutação que reseta o state de um módulo pelo operationId
*/
const createMutation = () => (state, { initialState = {} } = {}) => {
	state = JSON.parse(JSON.stringify(initialState))
}
	
	
/*
	Cria a mutação com o operationId e moduleName
	retorna um objeto com a chave certa para a mutation
	do vuex, proto para um merge: mutation: { ...estaMutation }
*/
export default ({ operationId, moduleName }) => ({
	[`${operationId}ResetState`]: createMutation({ operationId, moduleName })
})