/*
	Alterando apenas os parâmetros informados
*/
export default (state, parameters = {}) => 
	state.swagger.solicitacoes.parameters = {
		...state.swagger.solicitacoes.parameters,
		...parameters
	}
	

