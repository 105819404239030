<template>
	<div class="chart-popper" v-if="details && details.length > 0" ref="popper">

		<div 
			class="detail" 
			v-for="details, index in details"
			:key="index"
			:style="getInlineColorMapping(index)"
		>

			<div class="label" v-if="details.dataset?.label">{{ details.dataset.label }}: </div>
			<div class="value" >{{ details.data.y }}</div>
			
		</div>

	</div>
</template>

<script>
	
	import { getInlineColorMapping, applyHSLColorMappings } from '@/plugins/global'
	import { ref } from '@vue/reactivity';
	import { onMounted } from '@vue/runtime-core';
	export default {
		props: {
			details: Array,
			chartState: Object
		},
		setup(props){
			let popper = ref(null)
			onMounted( () => {
				let element = popper.value
				applyHSLColorMappings({ element, colorMappings: props.chartState.auxiliary.futureColorMapping })
			})
			return {
				popper, 
				getInlineColorMapping
			}
		}
	};
</script>

<style scoped>

	.detail {
		display: grid;
		grid-template-areas: "label value";
		justify-items: start;
		grid-template-columns: auto 1fr;
		gap: calc(var(--spacing) / 2);
	}

	.label{ 
		grid-area: label;
		color: hsla(var(--clr-h), var(--clr-s), var(--clr-l), var(--clr-a))
	}
	.value{ grid-area: value }


</style>