<template>
	<header>
		<MenuIconVue class="icon" @click.prevent.stop="toggleMenu" />
		<router-link to="/">
			<img src="../assets/e732bd_368bd863da9d41fa93e195d804e0c220~mv2.png" alt="Cartório de Sobradinho - 2º Ofício" />
		</router-link>
	</header>
</template>

<script>
	import { useStore } from 'vuex'
	import MenuIconVue from '@/components/icons/MenuIcon.vue'
	
	export default {
		components: {
			MenuIconVue
		},
		setup(){
			let store = useStore()
			let toggleMenu = () => store.commit("toggleMenu")
			return {
				toggleMenu
			}
		}
	}
</script>

<style scoped >

	header {
		background: var(--el-color-primary);
		color: var(--el-color-primary-light-9);
		display: grid;
		grid-auto-flow: column;
		align-items: center;
		min-width: 280px;
		z-index: 100;
		transition: transform 450ms ease-in;
		grid-area: header;
	}	
	@media (--medium-viewport){
		header {
			position: absolute;
			width: 100%;
			transform: translateY(-100%);
			top:0;
			left: 0;
			grid-area: 1/-1;
		}
	}
	
	a {
		padding: 0;
		margin: 0;
		width: fit-content;
		height: fit-content;
		margin-left: calc(-16px - var(--spacing)); 
		/* display: contents; seria o ideal, mas o suporte ainda é baixo */
		display: flex; 
	}

	img {
		height: 40px;
		border-radius: var(--spacing);
	}

	.icon {
		width: 32px;
		aspect-ratio: 1;
		fill:  var(--el-color-primary-light-9);
		padding: var(--spacing) var(--spacing-large);
		cursor: pointer;
	}
	
</style>