/*
	Módulos da nossa vuex store
*/

import createSwaggerSearchModule from "./SwaggerSearch/createSwaggerSearchModule"
import createSwaggerDeleteModule from "./SwaggerDelete/createSwaggerDeleteModule"
import createSwaggerListModule from "./SwaggerList/createSwaggerListModule"
import createSwaggerZoomModule from "./SwaggerZoom/createSwaggerZoomModule"
import createSwaggerUpdateModule from "./SwaggerUpdate/createSwaggerUpdateModule"
import createSwaggerCreateModule from "./SwaggerCreate/createSwaggerCreateModule"

/*
	Módulos de Busca
*/
const swaggerSearchModules = [
    {
        moduleName: 'PesquisarRegra',
        operationId: 'PesquisarRegra',
        parameters: {
            Pesquisa: '',
            Pagina: 1,
            TamanhoPagina: 20,
        }
    },
    {
        moduleName: 'PesquisarSetor',
        operationId: 'PesquisarSetor',
        parameters: {
            Pesquisa: '',
            Pagina: 1,
            TamanhoPagina: 20,
        }
    },
    {
        moduleName: 'ListarSetor',
        operationId: 'ListarSetor',
        parameters: {
            pagina: 1,
            tamanhoPagina: 10,
        }
    },
    {
        moduleName: 'PesquisarPerfil',
        operationId: 'PesquisarPerfil',
        parameters: {
            Pesquisa: '',
            Pagina: 1,
            TamanhoPagina: 20,
        }
    },
    {
        moduleName: 'usuarios',
        operationId: 'PesquisarUsuarios',
        parameters: {
            Pesquisa: '',
            Pagina: 1,
            TamanhoPagina: 10,
        }
    },
    {
        moduleName: 'colaboradores',
        operationId: 'PesquisarColaboradores',
        parameters: {
            Pesquisa: '',
            Pagina: 1,
            TamanhoPagina: 10,
        }
    },
    {
        moduleName: 'ObterTrilhosProcessuais',
        operationId: 'ObterTrilhosProcessuais',
        parameters: {
            search: '',
            mapaProcessualUid: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ObterRegrasTrilhoProcessual',
        operationId: 'ObterRegrasTrilhoProcessual',
        parameters: {
            search: '',
            trilhoProcessualId: 0,
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ObterEstadosProcessuais',
        operationId: 'ObterEstadosProcessuais',
        parameters: {
            search: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ObterMetaEmails',
        operationId: 'ObterMetaEmails',
        parameters: {
            search: '',
            trilhoProcessualUid: '',
            mapaProcessualUid: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListarPeriodosAusenciaColaboradorAsync',
        operationId: 'ListarPeriodosAusenciaColaboradorAsync',
        parameters: {
            uid: '',
            Pesquisa: '',
            Pagina: 1,
            TamanhoPagina: 50,
        }
    },
    {
        moduleName: 'ListarPeriodosAusencia',
        operationId: 'ListarPeriodosAusencia',
        parameters: {
            ColaboradorUid: '',
            UsuarioUid: '',
            ShouldBringAllResults: false,
            Pesquisa: '',
            Pagina: 1,
            TamanhoPagina: 10,
        }
    },
    {
        moduleName: 'ObterListaTipoAssunto',
        operationId: 'ObterListaTipoAssunto',
        parameters: {
            search: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListarTodosMapasProcessuais',
        operationId: 'ListarTodosMapasProcessuais',
        parameters: {
            search: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListarMetaTipoAnexo',
        operationId: 'ListarMetaTipoAnexo',
        parameters: {
            search: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ObterListaMetaHistorico',
        operationId: 'ObterListaMetaHistorico',
        parameters: {
            search: '',
            mapaProcessualUid: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ObterListaTipoEvento',
        operationId: 'ObterListaTipoEvento',
        parameters: {
            search: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListarMetaSolicitacao',
        operationId: 'ListarMetaSolicitacao',
        parameters: {
            search: '',
            mapaProcessualUid: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListarColaboradoresPorSetor',
        operationId: 'ListarColaboradoresPorSetor',
        parameters: {
            Pesquisa: '',
            NomeSetor: '',
            Pagina: 1,
            TamanhoPagina: 10,
        }
    },
    {
        moduleName: 'ListarMetaCampoAdicionalTrilhoProcessual',
        operationId: 'ListarMetaCampoAdicionalTrilhoProcessual',
        parameters: {
            search: '',
            trilhoProcessualUid: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListarMetaCampoAdicionalSolicitacao',
        operationId: 'ListarMetaCampoAdicionalSolicitacao',
        parameters: {
            search: '',
            metaSolicitacaoId: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListarPermissaoAtendimento',
        operationId: 'ListarPermissaoAtendimento',
        parameters: {
            search: '',
            permissaoSetorAtendimentoId: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListarPerfisExternos',
        operationId: 'ListarPerfisExternos',
        parameters: {
            searchText: '',
            page: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListarPerfisExternoUsuario',
        operationId: 'ListarPerfisExternoUsuario',
        parameters: {
            userId: '',
            page: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ListaGlobalAppSettings',
        operationId: 'ListaGlobalAppSettings',
        parameters: {
            search: '',
            usuarioId: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'ObterUltimaVersaoGlobalAppSettings',
        operationId: 'ObterUltimaVersaoGlobalAppSettings',
        parameters: {
            nome: '',
            usuarioUid: '',
        }
    },
    {
        moduleName: 'ListarTipoEventoMapaProcessual',
        operationId: 'ListarTipoEventoMapaProcessual',
        parameters: {
            search: '',
            mapaProcessualUid: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
    {
        moduleName: 'PesquisarAnexosProcesso',
        operationId: 'PesquisarAnexosProcesso',
        parameters: {
            search: '',
            pageIndex: 1,
            pageSize: 10,
        }
    },
]

/*
	Módulos de Delete
*/
const swaggerDeleteModules = [
    {
        moduleName: 'deleteColaborador',
        operationId: 'delete_admin_Colaborador__uid_',
    },
    {
        moduleName: 'AtivarDesativarEstadoProcessual',
        operationId: 'AtivarDesativarEstadoProcessual',
    },
    {
        moduleName: 'AtivarDesativarTipoAssunto',
        operationId: 'AtivarDesativarTipoAssunto',
    },
    {
        moduleName: 'AtivarDesativarMapaProcessual',
        operationId: 'AtivarDesativarMapaProcessual',
    },
    {
        moduleName: 'RemoverMetaTipoAnexo',
        operationId: 'RemoverMetaTipoAnexo',
    },
    {
        moduleName: 'RemoverMetaEmail',
        operationId: 'RemoverMetaEmail',
    },
    {
        moduleName: 'RemoverMetaHistorico',
        operationId: 'RemoverMetaHistorico',
    },
    {
        moduleName: 'RemoverTipoEvento',
        operationId: 'RemoverTipoEvento',
    },
    {
        moduleName: 'RemoverTrilhoProcessual',
        operationId: 'RemoverTrilhoProcessual',
    },
    {
        moduleName: 'RemoverMetaCampoAdicionalSolicitacao',
        operationId: 'RemoverMetaCampoAdicionalSolicitacao',
    },
    {
        moduleName: 'RemoverMetaParteInteressada',
        operationId: 'RemoverMetaParteInteressada',
    },
    {
        moduleName: 'RemoverMetaSolicitacao',
        operationId: 'RemoverMetaSolicitacao',
    },
    {
        moduleName: 'ExcluirMapaProcessual',
        operationId: 'ExcluirMapaProcessual',
    },
    {
        moduleName: 'RemoverMetaCampoAdicionalTrilhoProcessual',
        operationId: 'RemoverMetaCampoAdicionalTrilhoProcessual',
    },
    {
        moduleName: 'RemoverPerfilExterno',
        operationId: 'RemoverPerfilExterno',
    },
    {
        moduleName: 'RemoverPerfilExternoUsuario',
        operationId: 'RemoverPerfilExternoUsuario',
    },
    {
        moduleName: 'RemoverPermissaoAtendimento',
        operationId: 'RemoverPermissaoAtendimento',
    },
    {
        moduleName: 'RemoveAllContentAndKeysFromCache',
        operationId: 'RemoveAllContentAndKeysFromCache',
    },
    {
        moduleName: 'RemoverGlobalAppSettings',
        operationId: 'RemoverGlobalAppSettings',
    },
    {
        moduleName: 'ExcluirTipoSolicitacao',
        operationId: 'ExcluirTipoSolicitacao',
    },
    {
        moduleName: 'ExcluirInstrucaoDePreenchimento',
        operationId: 'ExcluirInstrucaoDePreenchimento',
    },
    {
        moduleName: 'RemoverEstadoProcessual',
        operationId: 'RemoverEstadoProcessual',
    },
    {
        moduleName: 'RemoverRegraTrilhoProcessual',
        operationId: 'RemoverRegraTrilhoProcessual',
    },
    {
        moduleName: 'RemoverSetor',
        operationId: 'RemoverSetor',
    },
    {
        moduleName: 'RemoverColaboradorSetor',
        operationId: 'RemoverColaboradorSetor',
    },
]

/*
	Módulos de Listagem
*/
const swaggerListModules = [
    {
        moduleName: 'ListarSetoresParaDespacho',
        operationId: 'ListarSetoresParaDespacho',
    },
    {
        moduleName: 'ListarUsuariosPorSetorParaDespacho',
        operationId: 'ListarUsuariosPorSetorParaDespacho',
    },
    {
        moduleName: 'ListarTipoSolicitacao',
        operationId: 'ListarTipoSolicitacao',
    },
    {
        moduleName: 'ListarMapasProcessuais',
        operationId: 'ListarMapasProcessuais',
    },
    {
        moduleName: 'ListarTipoAusencia',
        operationId: 'ListarTipoAusencia',
    },
    {
        moduleName: 'ListarTipoStatusAusencia',
        operationId: 'ListarTipoStatusAusencia',
    },
    {
        moduleName: 'ListarTipoSituacaoAusencia',
        operationId: 'ListarTipoSituacaoAusencia',
    },
    {
        moduleName: 'ListaTipoDestinatarioMetaEmail',
        operationId: 'ListaTipoDestinatarioMetaEmail',
    },
    {
        moduleName: 'ListaTipoTemplateMetaEmail',
        operationId: 'ListaTipoTemplateMetaEmail',
    },
    {
        moduleName: 'ObterListaTipoMetaHistorico',
        operationId: 'ObterListaTipoMetaHistorico',
    },
    {
        moduleName: 'ListaTipoMetaCampoAdicional',
        operationId: 'ListaTipoMetaCampoAdicional',
    },
    {
        moduleName: 'ListaTipoScriptValidacaoMetaCampoAdicional',
        operationId: 'ListaTipoScriptValidacaoMetaCampoAdicional',
    },
    {
        moduleName: 'ListaTipoEstruturaMetaCampoAdicional',
        operationId: 'ListaTipoEstruturaMetaCampoAdicional',
    },
    {
        moduleName: 'ListarPerfil',
        operationId: 'ListarPerfil',
    },
]

/*
	Módulos de Zoom (página interna "por uid")
*/
const swaggerZoomModules = [
    {
        moduleName: 'DetalharSetor',
        operationId: 'DetalharSetor',
    },
    {
        moduleName: 'ObterColaborador',
        operationId: 'ObterColaborador',
    },
    {
        moduleName: 'ObterUsuarioPorId',
        operationId: 'ObterUsuarioPorId',
    },
    {
        moduleName: 'ListarHistoricoAtendimento',
        operationId: 'ListarHistoricoAtendimento',
    },
    {
        moduleName: 'ListarHistoricoSolicitacao',
        operationId: 'ListarHistoricoSolicitacao',
    },
    {
        moduleName: 'ObterMetaSolicitacao',
        operationId: 'ObterMetaSolicitacao',
    },
    {
        moduleName: 'MostrarInstrucaoDePreenchimento',
        operationId: 'MostrarInstrucaoDePreenchimento',
    },
    {
        moduleName: 'ObterTipoSolicitacao',
        operationId: 'ObterTipoSolicitacao',
    },
    {
        moduleName: 'DetalharProcesso',
        operationId: 'DetalharProcesso',
    },
    {
        moduleName: 'DetalharProcessoParaAtendimento',
        operationId: 'DetalharProcessoParaAtendimento',
    },
    {
        moduleName: 'AtivarTipoSolicitacao',
        operationId: 'AtivarTipoSolicitacao',
    },
    {
        moduleName: 'DesativarTipoSolicitacao',
        operationId: 'DesativarTipoSolicitacao',
    },
    {
        moduleName: 'AtivarInstrucao',
        operationId: 'AtivarInstrucao',
    },
    {
        moduleName: 'DesativarInstrucao',
        operationId: 'DesativarInstrucao',
    },
    {
        moduleName: 'AtivarUsuario',
        operationId: 'AtivarUsuario',
    },
    {
        moduleName: 'DesativarUsuario',
        operationId: 'DesativarUsuario',
    },

    {
        moduleName: 'DesativarColaborador',
        operationId: 'DesativarColaborador',
    },

    {
        moduleName: 'AtivarColaborador',
        operationId: 'AtivarColaborador',
    },

    {
        moduleName: 'ExecuteQueryFromDataSourceQuery',
        operationId: 'ExecuteQueryFromDataSourceQuery',
    },
    {
        moduleName: 'ObterInstrucao',
        operationId: 'ObterInstrucao',
    },
    {
        moduleName: 'BaixarPdfUnico',
        operationId: 'BaixarPdfUnico',
    },
    {
        moduleName: 'ObterTrilhoProcessual',
        operationId: 'ObterTrilhoProcessual',
    },
    {
        moduleName: 'ObterRegraTrilhoProcessual',
        operationId: 'ObterRegraTrilhoProcessual',
    },
    {
        moduleName: 'ObterEstadoProcessual',
        operationId: 'ObterEstadoProcessual',
    },
    {
        moduleName: 'ObterMetaEmail',
        operationId: 'ObterMetaEmail',
    },
    {
        moduleName: 'ObterDetalhesTipoAssunto',
        operationId: 'ObterDetalhesTipoAssunto',
    },
    {
        moduleName: 'ObterMapaProcessual',
        operationId: 'ObterMapaProcessual',
    },
    {
        moduleName: 'ObterMetaTipoAnexo',
        operationId: 'ObterMetaTipoAnexo',
    },
    {
        moduleName: 'ObterDetalhesMetaHistorico',
        operationId: 'ObterDetalhesMetaHistorico',
    },
    {
        moduleName: 'ObterDetalhesTipoEvento',
        operationId: 'ObterDetalhesTipoEvento',
    },
    {
        moduleName: 'DuplicarMapaProcessual',
        operationId: 'DuplicarMapaProcessual',
    },
    {
        moduleName: 'ExportarWorkflow',
        operationId: 'ExportarWorkflow',
    },
    {
        moduleName: 'ObterMetaCampoAdicionalSolicitacao',
        operationId: 'ObterMetaCampoAdicionalSolicitacao',
    },
    {
        moduleName: 'ObterMetaParteInteressada',
        operationId: 'ObterMetaParteInteressada',
    },
    {
        moduleName: 'ObterDetalhesMetaSolicitacao',
        operationId: 'ObterDetalhesMetaSolicitacao',
    },
    {
        moduleName: 'ObterMetaCampoAdicionalTrilhoProcessual',
        operationId: 'ObterMetaCampoAdicionalTrilhoProcessual',
    },
    {
        moduleName: 'ListarDetalhesPermissaoAtendimento',
        operationId: 'ListarDetalhesPermissaoAtendimento',
    },
    {
        moduleName: 'ListarDetalhesPerfilExterno',
        operationId: 'ListarDetalhesPerfilExterno',
    },
    {
        moduleName: 'GetTwoFactorAuthenticationData',
        operationId: 'GetTwoFactorAuthenticationData',
    },
    {
        moduleName: 'GetAuthenticatorData',
        operationId: 'GetAuthenticatorData',
    },
    {
        moduleName: 'ListarDetalhesGlobalAppSettings',
        operationId: 'ListarDetalhesGlobalAppSettings',
    },
    {
        moduleName: 'RepararAnexoProcesso',
        operationId: 'RepararAnexoProcesso',
    },
]

/*
	Módulos de Update (via parâmetros e body normalmente)
*/
const swaggerUpdateModules = [
    {
        operationId: 'AlterarColaborador',
    },
    {
        operationId: 'RegistrarAusenciaColaborador',
    },
    {
        operationId: 'AtualizarUsuario',
    },
    {
        operationId: 'RedefinirSenhaUsuario',
    },
    {
        operationId: 'AlterarPartesInteressadas',
    },
    {
        operationId: 'AlterarTipoSolicitacao',
    },
    {
        operationId: 'AlterarCampoAdicionais',
    },
    {
        operationId: 'AtualizarInstrucao',
    },
    {
        operationId: 'AtualizarTrilhoProcessual',
    },
    {
        operationId: 'AtualizarTrilhoProcessualRegra',
        successAlert: 'Regra da Ação atualizada com sucesso!',
        successRoute: '/trilho-regra-detail/'
    },
    {
        operationId: 'AtualizarEstadoProcessual'
    },
    {
        operationId: 'AtualizarProcesso',
    },
    {
        operationId: 'AtualizarMetaEmail',
    },
    {
        operationId: 'AtualizarTipoAssunto'
    },
    {
        operationId: 'AtualizarMapaProcessual'
    },
    {
        operationId: 'AtualizarMetaTipoAnexo'
    },
    {
        operationId: 'AtualizarMetaHistorico'
    },
    {
        operationId: 'AtualizarTipoEvento'
    },
    {
        operationId: 'AtualizarMetaCampoAdicionalSolicitacao'
    },
    {
        operationId: 'AtualizarMetaParteInteressada'
    },
    {
        operationId: 'AtualizarMetaSolicitacao'
    },
    {
        operationId: 'AtualizarMetaCampoAdicionalTrilhoProcessual'
    },
    {
        operationId: 'AtualizarPermissaoAtendimento'
    },
    {
        operationId: 'AtualizarPerfilExterno'
    },
    {
        operationId: 'AlterarSetor'
    },
    {
        operationId: 'AtualizarPerfil'
    },
    {
        operationId: 'DownloadPersonalData'
    },
    {
        operationId: 'DeletePersonalData'
    },
    {
        operationId: 'CheckPassword'
    },
    {
        operationId: 'EnableAuthenticator2FA'
    },
    {
        operationId: 'ResetAuthenticator2FA'
    },
    {
        operationId: 'GenerateRecoveryCodes'
    },
    {
        operationId: 'ForgetTwoFactorClient'
    },
    {
        operationId: 'Disable2fa'
    },
    {
        operationId: 'AtualizarGlobalAppSettings'
    },
    {
        operationId: 'AtualizarAnexoProcesso'
    },
]

/*
	Módulos de Create (via parâmetros e body normalmente)
*/
const swaggerCreateModules = [
    {
        operationId: 'CriarUsuario',
    },
    {
        operationId: 'AdicionarAnexosAoDespacho',
    },
    {
        operationId: 'DespacharProcessoAtendimento',
    },
    {
        operationId: 'RegistrarPendencia',
        successAlert: 'Pendência registrada com sucesso',
        successRoute: '/atendimentos/',
    },
    {
        operationId: 'ResponderPendencia',
        successAlert: 'Resolução de pendência enviada com sucesso'
    },
    {
        operationId: 'ComplementarSolicitacao',
    },
    {
        operationId: 'AdicionarAnexos',
    },
    {
        operationId: 'SolicitarProcesso',
        successAlert: 'Processo cadastrado com sucesso!'
    },
    {
        operationId: 'CriarTipoSolicitacao',
    },
    {
        operationId: 'CriarInstrucao',
    },
    {
        operationId: 'InserirColaborador',
    },
    {
        operationId: 'InserirNovoTrilhoProcessual',
    },
    {
        operationId: 'InserirTrilhoProcessualRegra',
        successRoute: '/trilho-regra-detail/',
        successRouteProp: 'uid',
        successAlert: 'Regra da Ação criada com sucesso!'
    },
    {
        operationId: 'InserirEstadoProcessual',
    },
    {
        operationId: 'InserirMetaEmail',
    },
    {
        operationId: 'InserirNovoTipoAssunto',
    },
    {
        operationId: 'InserirMapaProcessual',
    },
    {
        operationId: 'InserirMetaTipoAnexo',
    },
    {
        operationId: 'InserirNovoMetaHistorico',
    },
    {
        operationId: 'InserirNovoTipoEvento',
    },
    {
        operationId: 'InserirMetaCampoAdicionalSolicitacao',
    },
    {
        operationId: 'InserirMetaParteInteressada',
    },
    {
        operationId: 'InserirMetaSolicitacao',
    },
    {
        operationId: 'InserirMetaCampoAdicionalTrilhoProcessual',
    },
    {
        operationId: 'InserirPermissaoAtendimento',
    },
    {
        operationId: 'InserirPerfilExterno',
    },
    {
        operationId: 'InserirPerfilExternoUsuario',
    },
    {
        operationId: 'InserirSetor',
    },
    {
        operationId: 'InserirPerfil',
    },
    {
        operationId: 'InserirColaboradorEmLote',
    },
    {
        operationId: 'AlterarColaboradorEmLote',
    },
    {
        operationId: 'InserirGlobalAppSettings',
    },
    {
        operationId: 'InserirNovaRegraTrilhoProcessual',
    },
    {
        operationId: 'RemoverAnexosDoDespacho',
    },
    {
        operationId: 'RemoverAnexosTemporarios',
    },
]


/*
	Registra o módulo dinamicamente
	mantendo o state dentro de store.state.swagger[moduleName]
*/
const registerSwaggerModules = store => {
    swaggerSearchModules.map(moduleInfo =>
        store.registerModule(['swagger', moduleInfo.moduleName || moduleInfo.operationId], createSwaggerSearchModule(moduleInfo))
    )
    swaggerDeleteModules.map(moduleInfo =>
        store.registerModule(['swagger', moduleInfo.moduleName || moduleInfo.operationId], createSwaggerDeleteModule(moduleInfo))
    )
    swaggerListModules.map(moduleInfo =>
        store.registerModule(['swagger', moduleInfo.moduleName || moduleInfo.operationId], createSwaggerListModule(moduleInfo))
    )
    swaggerZoomModules.map(moduleInfo =>
        store.registerModule(['swagger', moduleInfo.moduleName || moduleInfo.operationId], createSwaggerZoomModule(moduleInfo))
    )
    swaggerUpdateModules.map(moduleInfo =>
        store.registerModule(['swagger', moduleInfo.moduleName || moduleInfo.operationId], createSwaggerUpdateModule(moduleInfo))
    )
    swaggerCreateModules.map(moduleInfo =>
        store.registerModule(['swagger', moduleInfo.moduleName || moduleInfo.operationId], createSwaggerCreateModule(moduleInfo))
    )

}


/*
	Exportando a lista de módulos
*/
export {registerSwaggerModules}
