import { Alert } from "../../../plugins/notification"
import router from "../../../router"
import SwaggerRequest from "../../plugins/swagger/SwaggerRequest"

/*
	Cria a ação
*/
const createAction = ({ 
	operationId, 
	parametersBuilder, 
	bodyBuilder,
	successRoute,
	successRouteProp,
	successAlert }) => 
	(store, options = { }) => 
		new Promise( async( resolve ) => {
		
			let { commit, rootState } = store
			/*
				__TODO__
				avaliar se devemos trocar para getter isAppReady
			*/
			if(rootState.swagger.ready){

				/*
					Coletando o uid
					__TODO__ remover fallback para id
				*/
				let uid = options.parameters.uid || options.parameters.id

				/*
					Muda o loading para true
				*/
				commit(`isLoading${operationId}`,  { uid })

				/*
					Parâmetros
				*/
				let parameters = parametersBuilder({ store, options })
				
				/*
					Body
				*/
				let body = bodyBuilder({ store, options })
			
			
				/*
					Tenta executar a operação
				*/
				try {
					let response = await SwaggerRequest({
						operationId,
						parameters,
						body
					})

					/*
						Deu tudo certo
					*/
					if(response.ok){
						let result = JSON.parse(response.data)
					
						/*
							Se deu tudo certo e a resposta é uma string vazia
							mudamos para o Boolean true
						*/
						if(result === '')
							result = true
							
						/*
							Auto roteamento em caso de sucesso
						*/
						if(successRoute){
							let routeProp = successRouteProp ? result[successRouteProp] : ''
							router.push(successRoute+routeProp)
						}

						/*
							Commitando o resultado
						*/
						commit(`update${operationId}`, { uid, result })
						
						/*
							Alert de sucesso (Só dispara se for definido)
						*/
						Alert({ message:successAlert, type: 'success' })
						
						/*
							Resultado da Promise
						*/
						return resolve({ uid, result, parameters, body })

					}

					/*
						Não recebemos ok na nossa requisição
					*/
					let error = new Error("Problema com a requisição")
					
					commit(`update${operationId}`, { uid, error });

					/*
						Essa Promise sempre dispara Resolve
					*/
					console.log(`Erro com a operação ${operationId}`, response)
					return resolve({ uid, error, response, parameters, body })
					
				}catch(error){
					console.log(`Erro ao carregar ${operationId}`, error)
					commit(`update${operationId}`, { uid, error })

					/*
						Resolve apresenta o erro
					*/
					return resolve({ uid, error, parameters, body })
				}finally{
					/*
						Independente se deu certo ou não,
						não estamos mais carregando
					*/
					commit(`isLoading${operationId}`,  { uid, loading: false } )
					
				}

			}
		})

/*
	Cria a ação com o operationId e moduleName
	retorna um objeto com a chave certa para a action
	do vuex, proto para um merge: actions: { ...estaAction }
*/
export default ( options ) => {
	let { moduleName } = options
	return { [moduleName]: createAction( options ) }
}