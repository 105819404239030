import { isValidJSON } from "../../../plugins/props"
import SwaggerRequest from "../../plugins/swagger/SwaggerRequest"

/*
	Cria a ação vinculada à operação de listagem
*/

/*
	Cria a ação
*/
const createAction =
    ({ operationId }) =>
    async (store) => {
        {
            const { state, commit, rootState } = store;

            if (rootState.swagger.ready) {
                commit(`isLoading${operationId}`);

                const { parameters } = state;

                try {
                    const response = await SwaggerRequest({
                        operationId,
                        parameters,
                    });

                    if (isValidJSON(response.data)) {
                        let result = JSON.parse(response.data);

                        commit(`update${operationId}`, { result });
                    } else {
                        commit(`update${operationId}`, { result: response });
                    }
                } catch (error) {
                    console.log(`Erro ao buscar ${operationId}`, error);
                } finally {
                    commit(`isLoading${operationId}`, false);
                }
            }
        }
    };

/*
	Cria a ação com o operationId e moduleName
	retorna um objeto com a chave certa para a action
	do vuex, proto para um merge: actions: { ...estaAction }
*/
export default ({ operationId, moduleName }) => ({
	[operationId]: createAction({ operationId, moduleName })
})
