/*
	Acessando props profundas separadas por "."
*/
const deepProp = (obj = {}, prop='') => prop.split('.').reduce((a, b) => a?.[b], obj)

/*
	Setando valores em props profundas separadas por "."
*/
const deepSetProp = (obj = {}, prop='', value) => 
	prop.split('.').reduce((a, b, index, arr) => {
		if(index < arr.length-1) return a[b]
		a[b] = value
	}, obj)


/*
	Repassa do state local para a variável das props
*/
const fromStateToProps = (state, props, prop) => 
	Object.keys(state).map( key => props[prop][key] = state[key])
	
/*
	Computa o css de um elemento e retorna a lista
*/
const getPropsFromCSSVars = (element, propList = []) => {
	let computedStyle = getComputedStyle(element)
	return propList.map( prop => computedStyle.getPropertyValue(prop) )
}
	
/*
	Tenta buscar valores de css vars computados
	e aplica fallbacks para uso de cores HSLA
*/
const getHSLAValuesFromCSSVars = (element, colorsPropList = []) => 
	getPropsFromCSSVars(element, colorsPropList).map( color => 
		!color 
			? 0 
			: color.endsWith("%")
				? Number(color.substring(0, color.length-1))
				: Number(color)
	)

const isEmpty = (value) => {
	if (value === null || value === undefined || value === false || value === 0 || !value) {
		return true;
	}

	if (typeof value === 'object' && Object.keys(value).length === 0) {
		return true;
	}
	return false;
}

const isValidJSON = (stringValue) => {
    try {
        JSON.parse(stringValue);
        return true;
    } catch (error) {
		console.log(stringValue);
        return false;
    }
}

const resetObject = object => {
	if (isEmpty(object)) {
		return;
	}
	
    for (const [key, value] of Object.entries(object)) {
        switch (typeof value) {
            case 'string':
                object[key] = '';
			break;
            case 'number':
                object[key] = 0;
			break;
            case 'object':
                if (Array.isArray(value)) {
                    object[key] = [];
                } else if (Object.keys(value).length === 0) {
                    object[key] = {};
                } else {
                    resetObject(value);
                }
			break;
            case 'boolean':
                object[key] = false;
			break;
            default:
                object[key] = undefined;
			break;
        }
    }
}

const tryExecuteAction = action => {
	if (!action || typeof action !== 'function') {
		console.log('O parâmetro fornecido não é uma função.');
		return;
	}

	try {
		action();
	} catch (error) {
		console.log(`Ocorreu um erro na função ${action?.name || 'anônima'}:`, error);
	}
};

const replaceAllOccurrences = (inputString, pattern, replacement) => {
	let should = true;
	while (should) {
		if (inputString.includes(pattern)) {
			inputString = inputString.replace(pattern, replacement);	
		}
		else {
			should = false;
		}	
	}
	return inputString;
};

const groupArrayByProperty = (items, key, ordered) =>
    items.reduce((result, item) => {
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);

		if(ordered) {
			result[key].sort((a, b) => a[key] - b[key]);
		}
        return result;
    }, {});

const formatFileSize = size => {
    if (size < 1024) {
        return size.toFixed(2) + ' b';
    } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' Kb';
    } else {
        return (size / (1024 * 1024)).toFixed(2) + ' Mb';
    }
}

const mbToBytes = (mb) => mb * 1024 * 1024; //1 MB = 1024 * 1024 bytes

const getLastSettingsVersion = (settings, name, usuarioId) => {
    const lastVersion = settings.reduce(
        (prev, current) => {
            if (current.nome === name && current.usuarioId === usuarioId && current.versao > prev.versao) {
                return current;
            } else {
                return prev;
            }
        },
        { versao: 0 }
    );
    return lastVersion;
};

const transformActionNameToGerund = (action) => {
    const regex = /^(\w+)(.*)$/;
    const match = action.match(regex);

    if (match && match.length === 3) {
        const verboInfinitivo = match[1];
        const restanteDaFrase = match[2];

        const verboGerundio = verboInfinitivo.replace(/ar$/, "ando").replace(/er$/, "endo").replace(/ir$/, "indo");

        const newActionName = `${verboGerundio}${restanteDaFrase}`;

        return newActionName;
    } else {
        return action;
    }
};

const getIconForAction = etiqueta => {
	const actionsIcons = [
		{
			icon: 'EditPen',
			actions: ['coleta']
		},
		{
			icon: 'User',
			actions: ['atribuir']
		},
		{
			icon: 'Filter',
			actions: ['análise']
		},
		{
			icon: 'Switch',
			actions: ['redistribuir']
		},
		{
			icon: 'Refresh',
			actions: ['revalidar']
		},
		{
			icon: 'Calendar',
			actions: ['agendar']
		},
		{
			icon: 'Check',
			actions: ['aprovar', 'liberar']
		},
		{
			icon: 'Stamp',
			actions: ['corrigir', 'reprovar', 'registrar']
		},
		{
			icon: 'Money',
			actions: ['pagamento']
		},
		{
			icon: 'Finished',
			actions: ['finalizar']
		},
		{
			icon: 'QuestionFilled',
			actions: ['solucionar', 'responder']
		},
		{
			icon: 'Promotion',
			actions: ['enviar', 'encaminhar']
		},
	];
	const arrayLabel = etiqueta.split(' ').map(mp => mp.toLowerCase().trim()).reverse();
	const action = actionsIcons.find(act => act.actions.some(et => arrayLabel.includes(et.toLowerCase())));

	const icon = action?.icon ?? 'Promotion';
	return icon;
};

const getDatePeriod = (totalDay) => {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * parseInt(totalDay));
    return [start, end];
};

const base64ToBlob = (base64String, contentType) => {
    const byteCharacters = stringToUint8Array(base64String);

    const blob = byteArrayToBlob(byteCharacters, contentType);
    return blob;
};

const stringToUint8Array = (byteArrayString) => {
    const byteCharacters = atob(byteArrayString);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i);
    }
    return byteArray;
}

const byteArrayToBlob = (byteArray, contentType) => {
    return new Blob([byteArray], { type: contentType });
}

const byteArrayToFile = (byteArray, contentType, fileName) => {
    const blob = byteArrayToBlob(byteArray, contentType);
    return new File([blob], fileName, { type: contentType });
}

const blobToFile = (blob, contentType, fileName) => {
    return new File([blob], fileName, { type: contentType });
}

const getFormattedFilename = (name, uid, extension) => {
	const currentDate = new Date();
	const day = currentDate.getDate().toString().padStart(2, '0');
	const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
	const year = currentDate.getFullYear();
	const hours = currentDate.getHours().toString().padStart(2, '0');
	const minutes = currentDate.getMinutes().toString().padStart(2, '0');
	const seconds = currentDate.getSeconds().toString().padStart(2, '0');

	const cleanText = replaceAllOccurrences(uid, '-', '')

	return `${name}_${cleanText}_${day}${month}${year}_${hours}${minutes}${seconds}.${extension}`;
}

export { 
	deepProp,
	deepSetProp,
	fromStateToProps,
	getPropsFromCSSVars,
	getHSLAValuesFromCSSVars,
	isEmpty,
	isValidJSON,
	resetObject,
	tryExecuteAction,
	formatFileSize,
	mbToBytes,
	replaceAllOccurrences,
	groupArrayByProperty,
	getLastSettingsVersion,
	transformActionNameToGerund,
	getIconForAction,
	getDatePeriod,
	base64ToBlob,
	blobToFile,
	getFormattedFilename,
}