/*
	Gerenciamento de notificações global
*/
import { ElMessage } from "element-plus"

/*
	Alert Global 
	Sim, poderia apenas repassar as props,
	mas resolvi deixar claro as que mais importam
*/
const Alert = ({ type, message, ...options } = {}) =>
	message && ElMessage({ type, message, ...options })


export {
	Alert
}