/*
	Nossa integração com o swagger
*/
import SwaggerClient from 'swagger-client'
import spec from '../../fixtures/swagger.json'
import store from '../..'
import makeRequestInterceptor from './makeRequestInterceptor'



/*
	Exportando o buildRequest
	que sempre terá a mesma spec
*/
export default ({ operationId, body, parameters={}, options={} }) => {

	/*
		Swagger precisa estar pronto
	*/
	if(!store.state.swagger.ready) return

	/*
		Usando nosso interceptador de request
		(autenticação, contentType etc)
	*/
	let requestInterceptor = makeRequestInterceptor(store)

	let { client, operations } = store.state.swagger

	/*
		Verifica se essa operação requer multipartFormData
	*/
	
	let isMultiPartFormData = !!operations[operationId]?.requestBody?.content['multipart/form-data']

	/*
		Suporte para FormData, string e fallback
		para stringify de qualquer outro formato (como object)
		__TODO__ 
		recursivamente coletar os valores de objetos reativos do vue
		e substituir por objetos para não ter que fazer isso
		em cada elemento que utilize valores reativos
	*/
	let requestBody = body instanceof FormData 
		? body 
		: typeof body === "string" || isMultiPartFormData
			? body 
			: JSON.stringify(body)

	/*
		Criando a request	
	*/
	let request = SwaggerClient.buildRequest({
		spec,
		operationId,
		requestInterceptor,
		requestBody,
		parameters,
		...options
	})

	/*
		Executa o "fetch" e nos retorna a Promise
		Observe que temos algumas divergências em relação
		ao Fetch nativo:
		https://github.com/swagger-api/swagger-js/blob/HEAD/docs/usage/http-client.md
	*/
	
	return client.http(request)

}
	