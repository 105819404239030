export default async (store, options = {}) => {{
	let { state } = store
	/*
		__TODO__
		avaliar se devemos trocar para getter isAppReady
	*/
	if(state.swagger.ready){
		/*
			Trilho
		*/
		let {
			trilho,
		} = options

		/*
			Tenta carregar os dados
		*/
		console.log(trilho)
	}
}}
	

