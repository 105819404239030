import { oidc } from "../plugins/oidc"

/*
	Chamamos nosso sign in 
*/

export default async () => {

	try {
		const userManager = await oidc()
		await userManager.signoutRedirect()
		console.log("Tudo ok com o signOutRedirect")
	}catch(error){
		console.log("Problema ao tentar o signOutRedirect", error)
	}
	
}
