import { oidc } from "../plugins/oidc"

/*
	Chamamos nosso sign in 
*/

export default async () => {

	try {
		let userManager = await oidc()
		/*
			Coletando a página atual
		*/
		let { pathname } = window.location
		await userManager.signinRedirect({
			state: { pathname }
		})
		console.log("Tudo ok com o sign in Redirect")
	}catch(error){
		console.log("Problema ao tentar redirecionar para o SignIn", error)
	}
	
}
