import { createStore } from 'vuex'
import actions from './actions/index.js'
import mutations from './mutations/index.js'
import { registerSwaggerModules } from './modules'

/*
  Usar o webpack para isso depois, 
  ou remover completamente
*/
import oidc from './plugins/oidc/index.js'
import swagger from './plugins/swagger/index.js'
import appIsReady from './getters/appIsReady.js'
import tipoSolicitacaoEscolhido from './getters/tipoSolicitacaoEscolhido.js'
/*
  Onde guardamos nosso state inicial
*/
import makeInitialState from './makeInitialState.js'

/*
  Nossa store
*/
const store = createStore({
  state: {},
  actions,
  mutations,
  modules: {
    swagger: { } // nome (temporário?) para módulos gerados dinamicamente
  },
  getters: { appIsReady, tipoSolicitacaoEscolhido },
  plugins: [],
})

/*
  Registro dinâmico de módulos
*/
registerSwaggerModules(store)

/*
  Adicionando o state inicial após o registro de módulos do swagger
*/

let composedState = makeInitialState()

/*
  Apenas a parte do swagger tem alterações (aditivas)
  (precisa ser feito para cada 'namespace' de módulos)
*/
composedState.swagger = {
  ...composedState.swagger,
  ...Object.assign({}, store.state.swagger)
}

store.replaceState(composedState)

/*
  Plugins que podem depender de um state inicial
*/
oidc(store)
swagger(store)


/*
  Exportando a store
*/
export default store