import { computed } from "@vue/runtime-dom";


export const ROLES = {
    ADMIN: "admin",
    GODMODE: "godmode",
    CHEFE_ATENDENTE: "chefe-atendente",
    ANALISTA_ATENDENTE: "analista-atendente",
    CHEFE_MINUTA: "chefe-minuta",
    ANALISTA_MINUTA: "analista-minuta",
    CHEFE_LAVRATURA: "chefe-lavratura",
    ANALISTA_LAVRATURA: "analista-lavratura",
    CHEFE_PAGAMENTO: "chefe-pagamento",
    ANALISTA_PAGAMENTO: "analista-pagamento",
    ANALISTA: "analista",
    CHEFE: "chefe",
    ADVOGADO: "advogado",
    PARTE_INTERESSADA: "parte-interessada",
};

export const INTERNAL_ROLES = new Set([
    ROLES.ADMIN,
    ROLES.GODMODE,
    ROLES.CHEFE,
    ROLES.ANALISTA,
    ROLES.CHEFE_ATENDENTE,
    ROLES.ANALISTA_ATENDENTE,
    ROLES.CHEFE_MINUTA,
    ROLES.ANALISTA_MINUTA,
    ROLES.CHEFE_LAVRATURA,
    ROLES.ANALISTA_LAVRATURA,
    ROLES.CHEFE_PAGAMENTO,
    ROLES.ANALISTA_PAGAMENTO,
]);

export const EXTERNAL_ROLES = new Set([
    ROLES.PARTE_INTERESSADA,
    ROLES.ADVOGADO,
]);

export const SPECIAL_ROLES = new Set([
    ROLES.ADMIN,
    ROLES.GODMODE,
]);

/*
    Função para verificar se o usuário possui uma role específica
*/
const hasRole = (userRoles, role) => userRoles.has(role) || Array.from(userRoles).some(inner => inner.startsWith(role) || role.startsWith(inner));

/*
    Função para verificar se o usuário é autorizado por uma lista de roles
*/
const authorizeByRoles = (userRoles, roleOrRoles = []) => {
    const roles = new Set(Array.isArray(roleOrRoles) ? roleOrRoles : [roleOrRoles]);
    const isAdminOrGodMode = Array.from(SPECIAL_ROLES).some(role => userRoles.has(role));
    const hasRequiredRole = Array.from(roles).some(role => hasRole(userRoles, role));

    return isAdminOrGodMode || hasRequiredRole;
};

/*
    Nosso Hook de roles
*/
const useRoles = (oidc) => {
    const roles = computed(() => new Set([].concat(oidc?.user?.profile?.role || [])));

    return {
        roles,
        hasRole: role => hasRole(roles.value, role),
        authorizeByRoles: roleOrRoles => authorizeByRoles(roles.value, roleOrRoles),
        isExternalUser: computed(() => roles.value.size === 0 || authorizeByRoles(roles.value, Array.from(EXTERNAL_ROLES))),
        isInternalUser: computed(() => authorizeByRoles(roles.value, Array.from(INTERNAL_ROLES))),
    };
};

export { useRoles };
