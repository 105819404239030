/*
	Transforma nosso sitemap em rotas
*/
import sitemap from "."

const siteMapRoutes = ( currentViews = sitemap, routes = [] ) => {

	currentViews.map( ({ view, views, hideFromRouter, title, path, external }) => {
		if(!hideFromRouter || external){
			if(path && view) {
				routes = [
					...routes,
					{
						path, 
						name: title, 
						component: () => import(`../views/${view}.vue`)
					}
				]
			}
			if(views)
			routes = siteMapRoutes(views, routes);
		}
	});
	return routes
};

export default siteMapRoutes;