import { UserManager, WebStorageStateStore } from 'oidc-client'
import oidcSettings from './oidcSettings'
import { ElNotification } from 'element-plus'


const oidcPromise = new Promise(async (resolve) => {
	let settings = await oidcSettings()
	resolve(new UserManager({ ...settings, userStore: new WebStorageStateStore({ store: window.localStorage }) }))
  })

export const oidc = () => oidcPromise

export default async store => {
	const oidcUserManager = await oidc();

	oidcUserManager.events.AddUserLoaded = (user) => {
		console.log("user loaded", user)
		console.log("access token", user.access_token)
		store.commit('oidcUpdateUser');
	}

	oidcUserManager.events.addAccessTokenExpiring(async () => {
		try {
		  const user = await oidcUserManager.signinSilent()
		  console.log('Token renewed', user)
		} catch (error) {
		  console.error('Failed to renew token', error)
		}
	  })
	
	  oidcUserManager.events.addAccessTokenExpired(() => {
		console.log('Token expired');
		ElNotification({
			title: 'Token expirado',
			message: 'Sua sessão expirou. Por favor, faça o login novamente.',
			type: 'warning'
		  });
		setTimeout(() => {
			store?.dispatch('oidcLogout');
		  }, 5000);
	  })
	
	  oidcUserManager.events.addUserSignedOut(() => {
		console.log('User signed out')

		store.dispatch('oidcLogout')
	  })

	oidcUserManager.events.AddUserUnloaded = (e) => { console.log("user unloaded", e) }
	oidcUserManager.events.AddSilentRenewError = (e) => { console.log("user renew error", e) }
	oidcUserManager.events.AddUserSignedIn = (e) => { console.log("user signed in", e) }
	oidcUserManager.events.AddUserSessionChanged = (e) => { console.log("user session changed", e) }

	oidcUserManager.getUser().then( user => {
		// console.log("GetUser", user)
		store?.dispatch("oidcUpdateUser", { user })
	});

	store.commit('oidcUserManager', oidcUserManager);
}
