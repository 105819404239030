/*
	Cria a ação vinculada à operação
*/
const createAction = ({ operationId, initialState = {} }) => async ( { commit } ) =>
	/*
		Mutação 
	*/
	commit(`${operationId}ResetState`, { initialState })
	

/*
	Cria a ação com o operationId e moduleName
	retorna um objeto com a chave certa para a action
	do vuex, proto para um merge: actions: { ...estaAction }
*/
export default ({ operationId, moduleName, initialState }) => ({
	[`${operationId}ResetState`]: createAction({ operationId, moduleName, initialState })
})