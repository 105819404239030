/*
	Atualiza os parâmetros da operação
	Mudando apenas os campos informados (shallow merge)
*/
const createMutation = () => (state, parameters) => 
	state.parameters = {
		...state.parameters,
		...parameters
	}
	

/*
	Cria a mutação com o operationId e moduleName
	retorna um objeto com a chave certa para a mutation
	do vuex, proto para um merge: mutation: { ...estaMutation }
*/
export default ({ operationId, moduleName }) => ({
	[`${operationId}Parametros`]: createMutation({ operationId, moduleName })
})