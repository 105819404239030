import { ElNotification } from "element-plus";
import SwaggerRequest from "../plugins/swagger/SwaggerRequest";
import FileSaver from "file-saver";
import { isValidJSON } from "../../plugins/props";

export default async (store, options = {}) => {
    const { state, commit } = store;

    if (state.swagger.ready) {
        commit("isLoadingBaixarTodosAnexosProcesso");

        const { uid } = options;
        const parameters = { uid };

        try {
            const response = await SwaggerRequest({
                operationId: "BaixarTodosAnexosProcesso",
                parameters,
            });

            if (response.ok) {
                commit("updateBaixarTodosAnexosProcesso", response.data);
                
                if (
                    response.headers["content-type"] === "application/octet-stream" &&
                    response.headers["content-length"] > 0
                ) {
                    FileSaver.saveAs(response.data, "TodosAnexos.zip");
                    return;
                } else {
                    ElNotification({
                        title: "Problema ao baixar o anexo",
                        type: "warning",
                        message: "Tente novamente mais tarde",
                    });
                    return;
                }
            }
            ElNotification({
                title: "Problema ao baixar o anexo",
                type: "warning",
                message: "Tente novamente mais tarde",
            });
        } catch (error) {
            console.log("Erro BaixarTodosAnexosProcesso", error);
            ElNotification({
                title: "Problema ao baixar o anexo",
                type: "warning",
                message: "Tente novamente mais tarde",
            });
        } finally {
            commit("isLoadingBaixarTodosAnexosProcesso", false);
        }
    }
};
