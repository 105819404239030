import defaultBodyBuilder from "../Shared/Builders/defaultBodyBuilder"
import defaultParametersBuilder from "../Shared/Builders/defaultParametersBuilder"
import actionClear from "../Shared/actionClear"
import actionCreate from "./actionCreate"
import mutationClear from "../Shared/mutationClear"
import mutationIsLoading from "./mutationIsLoading"
import mutationUpdateResult from "./mutationUpdateResult"

/*
	Padronização de ações de Create 
	seguindo nossa especificação e estrutura do swagger
	Resulta em um módulo vuex
	https://vuex.vuejs.org/guide/modules.html

*/

export default ({ 
		moduleName, 
		operationId, 
		getters={}, 
		bodyBuilder = defaultBodyBuilder, 
		parametersBuilder = defaultParametersBuilder,
		successRoute,
		successRouteProp,
		successAlert,
	}) => { 

	/*
		Fallback para operationId, caso não especifique o moduleName
	*/
	moduleName ??= operationId

	/*
		Obrigatório
	*/
	if(!operationId) throw new Error('Precisamos de um operationId')

	/*
		Default state
	*/
	let state = {
		loading: false,
		result: undefined,
		error: undefined, 
	}
	
	/*
		Cópia profunda do state inicial
		(podemos usar para resetar a operação)
	*/
	let initialState = JSON.parse(JSON.stringify(state))

	/*
		Actions 
	*/
	let actions = {
		...actionCreate({ operationId, moduleName, bodyBuilder, parametersBuilder, successRoute, successRouteProp, successAlert }),
		...actionClear({ moduleName }),
	}

	/*
		Mutations
	*/
	let mutations = {
		...mutationIsLoading({ operationId, moduleName, initialState }),
		...mutationClear({ operationId, moduleName, initialState }),
		...mutationUpdateResult({ operationId, moduleName }),
	}

	return {
		state,
		actions,
		mutations,
		getters
	}

}
