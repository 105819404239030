<template>
	<div class="home" v-loading="!oidc?.ready"></div>
</template>

<script setup>
import { computed, onMounted, watch } from "vue-demi";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import sitemap from "../sitemap/index";

/*
	Por enquanto a home será um redirect
	Atendimentos para internos
	Solicitações para externos
*/
let store = useStore()
let oidc = computed(() => store.state.oidc)
let router = useRouter()

const goToUserHome = () => {
	if (!oidc.value)
		return;

	const allowedRoles = sitemap
		.filter((item) => item.title === "Atendimento")
		.flatMap((item) => item.allowedRoles)
		.filter((role) => role !== undefined);

	let userRoles = oidc.value.user?.profile?.role;

	if (!Array.isArray(userRoles)) {
		userRoles = userRoles ? [userRoles] : [];
	}

	if (oidc.value.ready && userRoles.some((userRole) =>
		allowedRoles.some((allowedRole) => userRole.startsWith(allowedRole)))) {
		return router.push("/atendimentos");
	}
	router.push("/processos-abertos")
}

watch(oidc, goToUserHome)
onMounted(goToUserHome)

</script>

<style scoped>
.home {
	display: grid;
	place-items: center;
}
</style>
