
/*
	Escolhe o tipo de solicitação que quer usar
*/
export default async ( { commit, dispatch, state }, { tipoSolicitacaoEscolhido }) => {
	
	/*
		Mutando o tipo de solicitação escolhido
	*/
	commit("EscolherTipoSolicitacao", { tipoSolicitacaoEscolhido })

	/*
		Coletando o tipo de solicitação por uid
	*/
	let tipoSolicitacao = state.swagger.listagemTiposSolicitacao.result.find( tipo => tipo.tipoSolicitacaoUid === tipoSolicitacaoEscolhido)

	/*
		Se por algum motivo não temos esse tipo de solicitação, só paramos por aqui
	*/
	if(!tipoSolicitacao) return

	/*
		Buscando a info extra relacionada a esse tipo de solicitação 
	*/
	dispatch("ObterMetaSolicitacao", { uid: tipoSolicitacao.mapaProcessualUid })

	/*
		Busca as instruções de preenchimento 
	*/
	dispatch("MostrarInstrucaoDePreenchimento", { uid:tipoSolicitacaoEscolhido })

}
	


