/*
	Abre o configurador de chart, informando o chartState
*/
export default (state, { chartState } = {}) => {
	/*
		Gerando um novo objeto para sermos reativos
		apenas ao chartState do novo chart configurado
	*/
	state.ChartConfigurator.isOpen		= true
	state.ChartConfigurator.chartState 	= chartState
}
