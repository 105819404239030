<template>
	<template v-if="!isReady">
		<div></div>
	</template>
	<template v-else>
		<el-config-provider :locale="locale">
			<div class="app">
				<HeaderVue class="header" />
				<AsideVue />
				<router-view class="content light home" v-slot="{ Component }">
					<transition 
						name="fade" 
						mode="out-in"
					>
						<component :is="Component" />
					</transition>
				</router-view>
			</div>
			<GlobalPopperVue />
		</el-config-provider>
	</template>
</template>
<script setup>

import { computed, reactive } from '@vue/runtime-core';
import { useStore } from 'vuex';
import AsideVue from "./components/Aside.vue";
import HeaderVue from './components/Header.vue';
import GlobalPopperVue from './components/GlobalPopper.vue';
import { ElConfigProvider, } from 'element-plus';
import ptBr from './plugins/element/locales/pt-br.mjs';
import skins from '@/plugins/skins'

const store = useStore()
const state = reactive({
	skinReady: false
})
/*
	Libera a app quando a skin foi aplicada
*/
const applySkin = async () => {
	await skins()
	state.skinReady = true
}

applySkin();

const isReady = computed(() => store.getters.appIsReady && state.skinReady)
const locale = computed(() => ptBr)

</script>
<style>
/*
		Definindo o tipo de layout pelas larguras suportadas
	*/

body {
	margin: 0;
	scroll-behavior: smooth;
	/*
			Scrollbar FF
		*/
	scrollbar-color: var(--el-color-primary-light-5) var(--el-color-primary);
	font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;

}

/*
		Mantendo a fonte consistente com o Element plus
	*/
.app {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
		"Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: auto 1fr auto;
	grid-template-areas:
		"header"
		"content"
		"footer";

	min-height: 100vh;
	scroll-behavior: smooth;
	background-color: var(--el-color-primary-light-9);

}

/*
		WebKit scrollbar
	*/
*::-webkit-scrollbar {
	width: var(--spacing);
	height: var(--spacing);
	background-color: var(--el-color-primary-light-5);
}

*::-webkit-scrollbar-thumb {
	background: var(--el-color-primary);
}

/*
		Layout com aside a partir do tamanho médio
	*/
@media (--medium-viewport) {
	.app {
		grid-auto-rows: 1fr auto;
		grid-template-columns: 280px 1fr;
		grid-template-areas:
			"aside 	content	"
			"aside 	footer	";
	}
}

/*
		Globais
	*/
.success {
	color: var(--color-success);
}

.fail {
	color: var(--color-warning);
}

/*
		a69770
		4d4844
	*/
.content {
	grid-area: content;
	min-width: 280px;
	padding: 30px 0;
}

.content .main {
	display: grid;
	grid-template-columns: 100%;
}

/*
		ContentArea de topLevel não precisa de padding
	*/
.nav {
	padding: 30px;
}

.nav a {
	font-weight: bold;
	color: #a0a09f;
}

.nav a.router-link-exact-active {
	color: #42b983;
}

/*
		Iframes do oidc
	*/
body>iframe {
	display: none;
}

/*
		Overrides globais
	*/

fieldset {
	border-color: var(--el-color-primary-light-8);
	border-radius: var(--spacing);
	padding: var(--spacing-large);
	margin-bottom: var(--spacing-large);
}

fieldset>* {
	margin-top: 0;
}

/*
		Altura máxima de botões tamanho "mini"
	*/
.el-button--mini {
	height: var(--input-mini-height);
}

/*
		Largura mínima do componente de arrastar arquivos
	*/
.el-upload .el-upload-dragger {
	min-width: calc(200px - calc(var(--spacing-large) * 2));
	padding-left: var(--spacing-large);
	padding-right: var(--spacing-large);
	max-width: 360px;
	width: unset;
}

/*
		Indicador de nível de atenção por classe
		nas linhas dos el-table
	*/
.el-table tr.warning td:first-child {
	border-left: 15px solid var(--el-color-warning);

}

.el-table {
	cursor: pointer;
}

/*.el-table tr.warning td {
		border-bottom: 2px solid var(--el-color-warning);		
	}*/



.el-table tr.danger td:first-child {
	border-left: 15px solid red
		/*var(--el-color-danger);*/
}

/*.el-table tr.danger td {
		border-bottom: 2px solid var(--el-color-danger);
	}*/


.el-table tr.success td:first-child {
	border-left: 15px solid var(--el-color-success);
}

.el-table tr.normal td:first-child {
	border-left: 15px solid var(--el-color-success);
}

/*
	.el-table tr.normal td {
		border-bottom: 2px solid var(--el-color-success);
	}*/


.el-form-item {
	flex-direction: column !important;
}

@media (--medium-viewport) {
	.el-form-item {
		flex-direction: row !important;
	}
}</style>
