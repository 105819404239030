import actionClear from "../Shared/actionClear"
import defaultBodyBuilder from "../Shared/Builders/defaultBodyBuilder"
import defaultParametersBuilder from "../Shared/Builders/defaultParametersBuilder"
import mutationClear from "../Shared/mutationClear"
import actionUpdate from "./actionUpdate"
import mutationIsLoading from "./mutationIsLoading"
import mutationUpdateResult from "./mutationUpdateResult"

/*
	Padronização de ações de Update 
	seguindo nossa especificação e estrutura do swagger
	Resulta em um módulo vuex
	https://vuex.vuejs.org/guide/modules.html

*/

export default ({ 
		moduleName, 
		operationId, 
		getters={}, 
		bodyBuilder = defaultBodyBuilder, 
		parametersBuilder = defaultParametersBuilder,
		successRoute,
		successRouteProp,
		successAlert,
	}) => { 

	/*
		Fallback para operationId, caso não especifique o moduleName
	*/
	moduleName ??= operationId

	/*
		Obrigatório
	*/
	if(!operationId) throw new Error('Precisamos de um operationId')

	/*
		Default state
		(começa vazio e é construído sob demanda)
		uids = {
			[uid]: initialUidState // ver abaixo
		}
	*/
	let state = {
		uids: {}
	}

	/*
		State inicial para cada uid 
	*/
	const initialUidState = {
		loading: false,
		result: undefined,
		error: undefined, 
		// {
		// 	code: Number,
		// 	message: String
		// },
	}

	/*
		Actions 
	*/
	let actions = {
		...actionUpdate({ operationId, moduleName, bodyBuilder, parametersBuilder, successRoute, successRouteProp, successAlert }),
		...actionClear({ moduleName })
	}
	
	/*
		Mutations
	*/
	let mutations = {
		...mutationIsLoading({ operationId, moduleName, initialUidState }),
		...mutationUpdateResult({ operationId, moduleName }),
		...mutationClear({ operationId, moduleName, initialState:initialUidState })
	}

	return {
		state,
		actions,
		mutations,
		getters
	}

}
