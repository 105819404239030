import SwaggerRequest from "../../plugins/swagger/SwaggerRequest"
import { isValidJSON } from "../../../plugins/props";

/*
	Cria a ação
*/
const createAction =
    ({ operationId }) =>
    async (store, { uid, colaboradorId }) => {
        {
            let { commit, rootState } = store;
            /*
		__TODO__
		avaliar se devemos trocar para getter isAppReady
	*/
            if (rootState.swagger.ready) {
                /*
			Muda o deleting para true
		*/
                commit(`isDeleting${operationId}`, { uid });

                /*
			Parâmetro desse delete
		*/
                const parameters = { uid, colaboradorId };

                /*
			Tenta executar a operação
		*/
                try {
                    let response = await SwaggerRequest({
                        operationId,
                        parameters,
                    });
                    let result = "";

                    if (isValidJSON(response.data)) {
                        result = JSON.parse(response.data);
                    } else if (typeof response.data === "Blob" && response.data?.size === 0) {
                        result = response.ok;
                    }

                    if (result === "") {
                        result = true;
                    }
                    commit(`update${operationId}`, { uid, result });
                } catch (error) {
                    console.log(`Erro ao deletar ${operationId}`, error);
                    commit(`update${operationId}`, { uid, error });
                } finally {
                    /*
				Independente se deu certo ou não,
				não estamos mais deletando
			*/
                    commit(`isDeleting${operationId}`, { uid, deleting: false });
                }
            }
        }
    };

/*
	Cria a ação com o operationId e moduleName
	retorna um objeto com a chave certa para a action
	do vuex, proto para um merge: actions: { ...estaAction }
*/
export default ({ operationId, moduleName }) => ({
	[operationId]: createAction({ operationId, moduleName })
})
