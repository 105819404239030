import { ElNotification } from "element-plus";
import SwaggerRequest from "../plugins/swagger/SwaggerRequest";
import FileSaver from "file-saver";

export default async (store, options = {}) => {
    {
        let { state } = store;
        /*
		__TODO__
		avaliar se devemos trocar para getter isAppReady
	*/
        if (state.swagger.ready) {
            /*
			Id
		*/
            let { uid, name } = options;

            /*
			Parametros
		*/
            let parameters = { uid };

            /*
			Tenta carregar os dados
		*/
            try {
                let response = await SwaggerRequest({
                    operationId: "ExcluirAnexo",
                    parameters,
                });

                /*
				Recebemos os dados
			*/
                if (response.ok) {
                    ElNotification({
						title: "Anexo",
						type: "success",
						message: "Anexo removido com sucesso!",
					});
                } else {
                    ElNotification({
                        title: "Problema ao excluir anexo",
                        type: "warning",
                        message: "Tente novamente mais tarde",
                    });
                }
            } catch (error) {
                console.log("Erro excluir anexo", error);
                /*
				Deu algo errado, apenas informa
			*/
                ElNotification({
                    title: "Problema ao excluir anexo",
                    type: "warning",
                    message: "Tente novamente mais tarde",
                });
            }
        }
    }
};
