import BaixarAnexo from "./BaixarAnexo"
import ExcluirAnexo from "./ExcluirAnexo"
import BaixarTodosAnexosProcesso from "./BaixarTodosAnexosProcesso"
import closeChartConfigurator from "./closeChartConfigurator"
import closeMenu from "./closeMenu"
import closePopper from "./closePopper"
import configureChart from "./configureChart"
import ConsultarProcessos from "./ConsultarProcessos"
import ConsultarProcessosParametros from "./ConsultarProcessosParametros"
import DesvincularInstrucaoComTipoSolicitacao from "./DesvincularInstrucaoComTipoSolicitacao"
import EscolherTipoSolicitacao from "./EscolherTipoSolicitacao"
import IniciarTrilho from "./IniciarTrilho"
import MinhasSolicitacoes from "./MinhasSolicitacoes"
import MinhasSolicitacoesParametros from "./MinhasSolicitacoesParametros"
import oidcSignIn from "./oidcSignIn"
import oidcSignInCallback from "./oidcSignInCallback"
import oidcSignOut from "./oidcSignOut"
import oidcLogout from "./oidcLogout"
import oidcUpdateUser from "./oidcUpdateUser"
import openMenu from "./openMenu"
import PesquisarColaboradores from "./PesquisarColaboradores"
import PesquisarInstrucoes from "./PesquisarInstrucoes"
import PesquisarInstrucoesParametros from "./PesquisarInstrucoesParametros"
import PesquisarTipoSolicitacao from "./PesquisarTipoSolicitacao"
import PesquisarTipoSolicitacaoParametros from "./PesquisarTipoSolicitacaoParametros"
import setReducedMotion from "./setReducedMotion"
import showPopper from "./showPopper"
import swaggerIsReady from "./swaggerIsReady"
import toggleMenu from "./toggleMenu"
import VincularInstrucaoComTipoSolicitacao from "./VincularInstrucaoComTipoSolicitacao"

/*
	Exportando as actions que usaremos
*/
export default {
	openMenu,
	closeMenu,
	toggleMenu,
	oidcSignInCallback,
	swaggerIsReady,
	oidcUpdateUser,
	oidcSignIn,
	oidcSignOut,
	oidcLogout,
	PesquisarColaboradores,
	EscolherTipoSolicitacao,
	MinhasSolicitacoes,
	MinhasSolicitacoesParametros,
	PesquisarInstrucoes,
	PesquisarInstrucoesParametros,
	VincularInstrucaoComTipoSolicitacao,
	DesvincularInstrucaoComTipoSolicitacao,
	PesquisarTipoSolicitacao,
	PesquisarTipoSolicitacaoParametros,
	BaixarAnexo,
    ExcluirAnexo,
	BaixarTodosAnexosProcesso,
	ConsultarProcessos,
	IniciarTrilho,
	ConsultarProcessosParametros,
	configureChart,
	closeChartConfigurator,
	setReducedMotion,
	showPopper,
	closePopper,
}
