import actionZoom from "./actionZoom"
import mutationIsLoading from "./mutationIsLoading"
import mutationUpdateResult from "./mutationUpdateResult"

/*
	Padronização de ações de Zoom (página interna)
	seguindo nossa especificação e estrutura do swagger
	Resulta em um módulo vuex
	https://vuex.vuejs.org/guide/modules.html

*/

const createSwaggerZoomModule = ({ moduleName, operationId, getters={} }) => { 

	/*
		Obrigatório
	*/
	if(!moduleName || !operationId) throw new Error('moduleName e operationId são requeridos')

	/*
		Default state
		(começa vazio e é construído sob demanda)
		uids = {
			[uid]: initialUidState // ver abaixo
		}
	*/
	let state = {
		uids: {}
	}

	/*
		State inicial para cada uid 
	*/
	const initialUidState = {
		loading: false,
		result: undefined,
		error: undefined, 
		// {
		// 	code: Number,
		// 	message: String
		// },
	}

	/*
		Actions 
	*/
	let actions = {
		...actionZoom({ operationId, moduleName }),
		
	}
	
	/*
		Mutations
	*/
	let mutations = {
		...mutationIsLoading({ operationId, moduleName, initialUidState }),
		...mutationUpdateResult({ operationId, moduleName }),
		
	}

	return {
		state,
		actions,
		mutations,
		getters
	}

}

export default createSwaggerZoomModule