import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import siteMapRoutes from "../sitemap/siteMapRoutes";
import store from "../store";
import oidcSettings from "../store/plugins/oidc/oidcSettings";
// import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'

/*
  Nossas rotas
  Index definido estaticamente
  Demais rotas extraídas dinamicamente do sitemap
*/
const routes = [
    {
        path: "/signin-callback-oidc",
        beforeEnter: async (to, from, next) => {
            /*
              Rota de callback, apenas esperamos
              o fluxo natural do swagger e do oidc
              se comunicarem via actions e mutations da stora
              next(false) corta o processamento dessa rota
            */
            next(false);
        },
    },
    {
        path: "/signout",
        beforeEnter: async (to, from, next) => {
            await store.dispatch("oidcSignOut");
            next(false);
        },
    },
    {
        path: "/id/:oidcpath(.*)",
        redirect: async (to) => {
            let settings = await oidcSettings();
            window.location.href = settings.authority + "/" + to.params.oidcpath;
            return false;
        },
    },
    { path: "/", name: "Home", component: Home },
    ...siteMapRoutes(),
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        let position = savedPosition ? { savedPosition } : { top: 0 };
        return {
            el: ".home",
            ...position,
            behavior: "smooth",
        };
    },
});

router.beforeEach((to, from) => to.name === from.name || store.dispatch("closeMenu"));

router.afterEach((to, from) => {});

// router.beforeEach(vuexOidcCreateRouterMiddleware(store))

export default router;
