import logOperationId from "../logger"

/*
	Define se estamos ou não deletando,
	baseado no id da operação e uid do registro
*/
const createMutation = ({ operationId }) => (state, { uid, deleting=true }) => {
	state.uids[uid] = {
		...(state.uids[uid] || {}),
		deleting 
	}

	// logOperationId(`isLoading${operationId}`, { uid, deleting });
}

/*
	Cria a mutação com o operationId e moduleName
	retorna um objeto com a chave certa para a mutation
	do vuex, proto para um merge: mutation: { ...estaMutation }
*/
export default ({ operationId, moduleName }) => ({
	[`isDeleting${operationId}`]: createMutation({ operationId, moduleName })
})