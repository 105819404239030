<template>
	<div class="popper" ref="tooltip" :class="{ show: Popper.show }">
		<component :is="Popper.component" v-bind="Popper.props" v-if="Popper.component" />
	</div>
</template>

<script>
	import { computed, onMounted, onUnmounted, reactive, ref } from '@vue/runtime-core';
	import { useStore } from 'vuex';
	import { createPopper } from '@popperjs/core'
	import ChartPopperVue from './charts/ChartPopper.vue';
	
	/*
		Helper para gerar novos elementos virtuais
	*/
	const generateGetBoundingClientRect = (x = 0, y = 0) => 
		() => ({
			width: 0,
			height: 0,
			top: y,
			right: x,
			bottom: y,
			left: x,
		})
	
	const baseOffsetX = 10
	const baseOffsetY = 20

	/*

		Popper global
	*/
	export default {

		setup(){

			/*
				Se tivermos tempo, refatorar
				nosso Popper da pra conseguir
				aproximadamente 2kb no tree shaking
			*/
			let tooltip = ref(null)
			let state = reactive({
				instance: undefined,
				destroy: undefined
			})

			let store = useStore()
			const Popper = computed(() => store.state.Popper )

			const trackMouse = () => {

				const virtualElement = {
					getBoundingClientRect: generateGetBoundingClientRect(),
				}

				/*
					Nova instância do popper
				*/
				const instance = createPopper(virtualElement, tooltip.value, {
					placement: 'right',
					modifiers: [
						{ 
							name: 'offset',
							options: {
								offset: [baseOffsetY, baseOffsetX],
							},
						},
						
					]
				} )
				state.instance = instance

				/*
					Observer do mouseMove
				*/
				const mouseMoveHandler = ({ clientX: x, clientY: y }) => {
					let { offsetX, offsetY } = Popper.value
					virtualElement.getBoundingClientRect = generateGetBoundingClientRect(x + offsetX, y + offsetY)
					instance.update()
				}

				/*
					Iniciando a observação
				*/
				document.addEventListener('mousemove', mouseMoveHandler)
				
				/*
					Preparando o 'unobserve' para o unmount
				*/
				state.destroy = () => document.removeEventListener('mousemove', mouseMoveHandler)


			}

			onMounted( () => {
				trackMouse()
			})

			onUnmounted(() => state.destroy && state.destroy() )

			let c = ChartPopperVue

			return {
				c,
				Popper,
				tooltip
			}
		}

	};
</script>

<style scoped>

	.popper {
		z-index: 10000;
		border-radius: var(--spacing);
		box-shadow: var(--shd);
		padding: var(--spacing);
		background-color: var(--el-color-white);
		opacity: 0;
		transition: opacity 450ms linear;
		pointer-events: none;
	}

	.show {
		opacity: 1;
	}

</style>