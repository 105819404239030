import siteMapMenu from '../sitemap/siteMapMenu.js'
import devState from './fixtures/devState.js'

/*
  Gera uma cópia profunda do initial state
*/
export default () => JSON.parse(JSON.stringify({
  /*
    Gerando o menu de acordo com nosso sitemap
  */
  menu: siteMapMenu(),
  /*
    Configurador de Charts
  */
  ChartConfigurator: {
    isOpen: false,
    chartState: undefined
  },
  Motion: {
    reducedMotion: false,
    fastDuration: 250,
    normalDuration: 650,
    slowDuration: 1400,
  },
  Popper: {
    show: false,
    virtualElement: undefined,
    component: undefined,
    props: undefined,
  },
  /*
    Controle de estado do aside
  */
  aside: {
    isOpen: false,
  },
  oidc: {
    ready: false,
    user: null,
  },
  oidcUserManager: { },
  swagger: {
    ready: false,
    colaboradores: {
      loading: false,
      parameters: {
        Pesquisa: "",
        Pagina: 1,
        TamanhoPagina: 10,
      },
      result: {
        "numeroDePaginas": 0,
        "registrosPorPagina": 0,
        "totalDeRegistros": 0,
        "pagina": 0,
        "registrosNaPagina": 0,
        "registros": [
          /*
            {
              "uid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              "nome": "string",
              "cpf": "string",
              "telefone": "string",
              "email": "string",
              "perfil": "string",
              "setor": "string",
              "oab": "string",
              "criadoEm": "2021-07-03T06:03:08.137Z"
            }
          */
        ]
      }
    },
    solicitacoes: {
      loading: false,
      parameters: {
        Pagina: 1,
        TamanhoPagina: 10,
        NumeroProcesso: "",
        TipoSolicitacaoUid: "",
        DataInicio: "",
        DataFim: "",
        StatusAtendimento: "",
        Documento: "",
        Nome: "",
        Pesquisa: "",
        TipoSetorUid: "",
        AtendenteUid: "",
			  EstadoProcessualUid: ""
      },
      result: {
        "numeroDePaginas": 1,
        "registrosPorPagina": 10,
        "totalDeRegistros": 0,
        "pagina": 1,
        "registrosNaPagina": 0,
        "registros": [
          /*
            {
              "numeroProcesso": "string",
              "nomeSolicitante": "string",
              "documentoSolicitante": "string",
              "usuario": "string",
              "nome": "string",
              "setorResponsavel": "string",
              "dataSolicitacao": "2021-07-01T21:52:02.731Z",
              "processoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              "statusAtendimento": "string",
              "titulo": "string"
            } 
          */
        ]
      }
    },
    atendimentos: {
      loading: false,
      parameters: {
        Pagina: 1,
        TamanhoPagina: 10,
        NumeroProcesso: "",
        TipoSolicitacaoUid: "",
        DataInicio: "",
        DataFim: "",
        StatusAtendimento: "",
        Documento: "",
        Nome: "",
        Pesquisa: "",
        TipoSetorUid: "",
        AtendenteUid: "",
        AtendenteSecretariaUid: "",
			  EstadoProcessualUid: ""
      },
      result: {
        "numeroDePaginas": 1,
        "registrosPorPagina": 10,
        "totalDeRegistros": 0,
        "pagina": 1,
        "registrosNaPagina": 0,
        "registros": [
          /*
            {
              "numeroProcesso": "string",
              "nomeSolicitante": "string",
              "documentoSolicitante": "string",
              "usuario": "string",
              "nome": "string",
              "setorResponsavel": "string",
              "dataSolicitacao": "2021-07-13T12:46:00.702Z",
              "processoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              "statusAtendimento": "string",
              "titulo": "string",
              "dataHoraUltimaAtualizacao": "2021-07-13T12:46:00.702Z"
            } 
          */
        ]
      }
    },
    instrucoesDePreenchimento: {
      loading: false,
      parameters: {
        TamanhoPagina: 10,
        Pagina: 1,
        Pesquisa: "",
        MapaProcessualUid: "",
      },
      result: {
        "numeroDePaginas": 1,
        "registrosPorPagina": 10,
        "totalDeRegistros": 0,
        "pagina": 1,
        "registrosNaPagina": 0,
        "registros": [
          /*
            {
              "numeroDePaginas": 0,
              "registrosPorPagina": 0,
              "totalDeRegistros": 0,
              "pagina": 0,
              "registrosNaPagina": 0,
              "registros": [
                {
                "instrucaoPreenchimentoId": 0,
                "titulo": "string",
                "tipoMensagem": "string",
                "tipoSolicitacao": [
                  "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                ],
                "vinculos": 0
                }
              ]
            }
          */
        ]
      }
    },
    novaSolicitacao: {
      tipoSolicitacaoEscolhido: undefined,
      instrucaoDePreenchimento: undefined,
      metaSolicitacao: {
        loading: false,
        result: undefined,
        /*
          {
            "mapaProcessualUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "setorAlvo": "string",
            "usuarioAlvo": "string",
            "metaCamposAdicionais": [
              {
                "nome": "string",
                "etiqueta": "string",
                "tipo": "string",
                "anexoExtensoesAceitas": "string",
                "anexoTamanhoMaximo": 0,
                "anexoTamanhoMinimo": 0,
                "id": "string"
              }
            ],
            "metaPartesInteressadas": [
              {
                "etiqueta": "string",
                "relacao": "string",
                "documentoObrigatorio": true,
                "emailObrigatorio": true,
                "telefoneObrigatorio": true,
                "parteObrigatoria": true
              }
            ]
          }   
        */
      },
      anexos: [],
      envio: {
        loading: false,
        result: undefined
      }
    },
    solicitacao: {
      loading:false,
      result: undefined,
      // {
      //   "numeroProcesso": "string",
      //   "nomeSolicitante": "string",
      //   "documentoSolicitante": "string",
      //   "usuario": "string",
      //   "nome": "string",
      //   "setorResponsavel": "string",
      //   "dataHoraSolicitacao": "2021-07-11T13:56:38.904Z",
      //   "partesInteressadas": [
      //     {
      //       "documento": "string",
      //       "nome": "string",
      //       "email": "string",
      //       "telefone": "string"
      //     }
      //   ],
      //   "camposAdicionais": [
      //     {
      //       "nome": "string",
      //       "valor": "string",
      //       "etiqueta": "string",
      //       "frontendId": "string"
      //     }
      //   ],
      //   "anexos": [
      //     {
      //       "nome": "string",
      //       "anexoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //       "tamanho": 0,
      //       "extensao": "string"
      //     }
      //   ]
      // }
    },
    atendimento: {
      loading:false,
      result: undefined,
      // {
      //   "numeroProcesso": "string",
      //   "nomeSolicitante": "string",
      //   "documentoSolicitante": "string",
      //   "usuario": "string",
      //   "nomeTipoSolicitacao": "string",
      //   "setorResponsavel": "string",
      //   "dataHoraSolicitacao": "2021-07-13T13:37:13.253Z",
      //   "partesInteressadas": [
      //     {
      //       "documento": "string",
      //       "nome": "string",
      //       "email": "string",
      //       "telefone": "string"
      //     }
      //   ],
      //   "camposAdicionais": [
      //     {
      //       "nome": "string",
      //       "valor": "string",
      //       "etiqueta": "string",
      //       "frontendId": "string"
      //     }
      //   ],
      //   "anexos": [
      //     {
      //       "nome": "string",
      //       "anexoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //       "tamanho": 0,
      //       "extensao": "string"
      //     }
      //   ],
      //   "trilhosProcessuais": [
      //     {
      //       "nome": "string",
      //       "etiqueta": "string",
      //       "habilitado": true,
      //       "trilhoProcessualUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //       "metaCamposAdicionais": [
      //         {
      //           "nome": "string",
      //           "etiqueta": "string",
      //           "tipo": "string",
      //           "anexoExtensoesAceitas": "string",
      //           "anexoTamanhoMaximo": 0,
      //           "anexoTamanhoMinimo": 0,
      //           "id": "string"
      //         }
      //       ]
      //     }
      //   ],
      //   "nomeEstadoProcessual": "string",
      //   "usuarioAtendimento": "string",
      //   "descricaoSolicitacao": "string",
      //   "dataHoraUltimaAtualizacao": "2021-07-13T13:37:13.254Z",
      //   "tituloSolicitacao": "string"
      // }
    },
    novaInstrucaoDePreenchimento: {
      envio: {
        loading: false,
        result: undefined
      }
    },
    novoTipoSolicitacao: {
      envio: {
        loading: false,
        result: undefined
      }
    },
    novoColaborador: {
      envio: {
        loading: false,
        result: undefined
      }
    },
    BaixarTodosAnexosProcesso: {
      loading: false,
      result: undefined
    },
    tiposDeSolicitacao: {
      loading: false,
      parameters: {
        TamanhoPagina: 10,
        Pagina: 1,
        Pesquisa: "",
      },
      result: {
        "numeroDePaginas": 0,
        "registrosPorPagina": 0,
        "totalDeRegistros": 0,
        "pagina": 0,
        "registrosNaPagina": 0,
        "registros": [
          // {
          //   "descricao": "string",
          //   "nome": "string",
          //   "assuntoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          //   "assuntoNome": "string",
          //   "tipoSolicitacaoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          //   "mapaProcessualUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          //   "instrucaoPreenchimentoId": 0,
          //   "instrucao": "string"
          // }
        ]
      }
    },
    listagemTiposSolicitacao: {
      loading:false,
      result: [
        /*
          {
            "descricao": "string",
            "nome": "string",
            "assuntoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "assuntoNome": "string",
            "tipoSolicitacaoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "mapaProcessualId": 0,
            "mapaProcessualUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
          }
        */
      ]
    },
    instrucaoDePreenchimento: {
      loading: false,
      result: undefined,
      /*
        "instrucaoDePreenchimentoId": 0,
        "titulo": "string",
        "tipoMensagem": "string",
        "mensagem": "string",
        "tiposSolicitacao": [
          {
            "descricao": "string",
            "nome": "string",
            "tipoSolicitacaoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "assuntoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "nomeAssunto": "string"
          }
        ] 
      */
      envio: {
        loading: false,
        result: undefined
      }
    },
    tipoSolicitacao: {
      loading: false,
      result: undefined,
      /*
        "descricao": "string",
        "nome": "string",
        "tipoSolicitacaoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "assuntoUid": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "nomeAssunto": "string"
      */
      envio: {
        loading: false,
        result: undefined
      }
    },
  },
  /*
    Overrides de desenvolvimento
  */
  ...devState
}))
