import actionDelete from "./actionDelete"
import mutationIsDeleting from "./mutationIsDeleting"
import mutationUpdateResult from "./mutationUpdateResult"

/*
	Padronização de ações de DELETE seguindo 
	nossa especificação e estrutura do swagger
	Resulta em um módulo vuex
	https://vuex.vuejs.org/guide/modules.html

*/

const createSwaggerDeleteModule = ({ moduleName, operationId, getters={} }) => { 

	/*
		Obrigatório
	*/
	if(!moduleName || !operationId) throw new Error('moduleName e operationId são requeridos')

	/*
		Default state
		uids = {
			[uid]: {
				deleting: Boolean,
				result: Boolean,
				error: {
					code: Number,
					message: String
				},
			}
		}
	*/
	let state = {
		uids: {}
	}

	/*
		Actions 
	*/
	let actions = {
		...actionDelete({ operationId, moduleName }),
		
	}
	
	/*
		Mutations
	*/
	let mutations = {
		...mutationIsDeleting({ operationId, moduleName }),
		...mutationUpdateResult({ operationId, moduleName })
		
	}

	return {
		state,
		actions,
		mutations,
		getters
	}

}

export default createSwaggerDeleteModule