/*

	Injeta variáveis css a um elemento seguindo
	um padrão de color mapping e hsla
	de: colorMappings: [ { h:120, s: 50, l:50, a: 0 } ]
	para: [elemento] {
		--clr-h-0: 120,
		--clr-s-0: 50%,
		--clr-l-0: 50%,
		--clr-a-0: 0,
	}

	Possivelmente podemos inserir isso ao design system
	Por enquanto é um módulo separado

	colorMappings: [
		// cologMapping
		{
			h: hue,    		
			s: saturation,	// Sem %
			l: lightness,	// Sem %
			a: 0
		}
	]
*/
export default ({ element, colorMappings }) => {
	colorMappings.map( (colorMapping, index) => {
		element.style.setProperty(`--clr-h-${index}`, colorMapping.h)
		element.style.setProperty(`--clr-s-${index}`, colorMapping.s + "%")
		element.style.setProperty(`--clr-l-${index}`, colorMapping.l + "%")
		element.style.setProperty(`--clr-a-${index}`, colorMapping.a)
	})
}

	