import store from "../../store/index";
import sitemap from "../../sitemap/index";
import { ElNotification } from "element-plus";

const getAllowedRolesByPageTitle = (pageTitle) =>
    sitemap
        .filter((item) => item.title === pageTitle)
        .flatMap((item) => item.allowedRoles)
        .filter((role) => role !== undefined);

const hasPermission = (userRoles, allowedRoles) =>
    store.state.oidc.ready && userRoles.some((role) => allowedRoles.includes(role) || allowedRoles.some(inner => role.startsWith(inner)));

const permissionDirective = {
    mounted(el, binding, vnode) {
        const { pageTitle } = binding.value;

        const allowedRolesForPage = getAllowedRolesByPageTitle(pageTitle);
        const allowedRolesForPageDefault = getAllowedRolesByPageTitle('Meus Processos');

        let userRoles = store.state.oidc.ready && store.state.oidc.user.profile.role;

        if (!Array.isArray(userRoles)) {
            userRoles = userRoles ? [userRoles] : [];
        }

        const hasPermissionForPage = hasPermission(userRoles, allowedRolesForPage);
        const hasPermissionForPageDefault = hasPermission(userRoles, allowedRolesForPageDefault);

        if (!hasPermissionForPage) {
            if (hasPermissionForPageDefault) {
                window.location.href = "/processos-abertos";
            } else {
                ElNotification({
                    title: "Acesso negado",
                    message:
                        "Você não possui permissão pra acessar essa página! Por favor, contate o administrador do sistema.",
                    type: "error",
                });
                setTimeout(() => {
                    store?.dispatch("oidcLogout");
                }, 3000);
            }
        }
    },
};

export default permissionDirective;
