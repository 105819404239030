import SwaggerRequest from "../plugins/swagger/SwaggerRequest"

/*
	Pesquisa de colaboradores
	__TODO__
	avaliar se devemos criar uma curried function
	que já expõe o que vai ser utilizado,
	incluindo o client swagger e o request interceptor
*/
export default async (store) => {{
	let { state, commit } = store
	/*
		__TODO__
		avaliar se devemos trocar para getter isAppReady
	*/
	if(state.swagger.ready){
		/*
			Muda o loading para true
		*/
		commit("isLoadingPesquisarInstrucoes")

		/*
			Parâmetros vem do state das solicitações
		*/
		let { parameters } = state.swagger.instrucoesDePreenchimento
		/*
			Tenta carregar os dados
		*/
		try {
			let response = await SwaggerRequest({
				operationId: "PesquisarInstrucoes",
				parameters
			})
			let result = JSON.parse(response.data)
			commit("updatePesquisarInstrucoes", { result })
		}catch(error){
			console.log("Erro ao buscar os PesquisarInstrucoes", error)
		}finally{
			/*
				Independente se deu certo ou não,
				não estamos mais carregando
			*/
			commit("isLoadingPesquisarInstrucoes", false)
		}
	}
}}
	