/*
	Altera valores dos parâmetros para essa operação
*/
export default async ( { commit, dispatch }, parameters = {} ) => {

	/*
		Comita as alterações de parâmetros
	*/
	commit("ConsultarProcessosParametros", parameters)

	/*
		ReFetch da operação
	*/
	dispatch("ConsultarProcessos")

}