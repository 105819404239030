import logOperationId from "../logger"

/*
	Define se estamos ou não carregando,
	baseado no nome do módulo e id da operação
*/
const createMutation = ({ operationId }) => (state, loading=true) => {
	state.loading = loading

	// logOperationId(`isLoading${operationId}`, { loading });
}
/*
	Cria a mutação com o operationId e moduleName
	retorna um objeto com a chave certa para a mutation
	do vuex, proto para um merge: mutation: { ...estaMutation }
*/
export default ({ operationId, moduleName }) => ({
	[`isLoading${operationId}`]: createMutation({ operationId, moduleName })
})