export default
	{
		"numeroDePaginas":1,
		"registrosPorPagina":5,
		"totalDeRegistros":1,
		"pagina":1,
		"registrosNaPagina":1,
		"registros":[
			{
				"uid":"34e456c4-305a-4cb7-a4a8-d8e1c0d7cedf",
				"nome":"admin",
				"cpf":"11111111111",
				"telefone":null,
				"email":"admin@cartorio.dev",
				"perfil":"Administrador",
				"setor":"Administração",
				"oab":null,
				"criadoEm":"2021-06-11T14:02:21.591635+00:00"
			}
		]
	}