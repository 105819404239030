/*
	Limpa o Popper Global
*/
export default (state) => {
	clearTimeout(state.Popper.removeComponentTimeout)
	state.Popper.component 		= undefined
	state.Popper.virtualElement	= undefined 
	state.Popper.props 			= undefined
}
	
