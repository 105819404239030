/*
	Cria a ação vinculada à operação de listagem
*/
/*
	Cria a ação
*/
const createAction = ({ operationId, initialParameters }) => async ( { commit, dispatch }, parameters ) => {

	/*
		Suporte para resetar os parâmetros
	*/
	if(!parameters) 
		parameters = JSON.parse(JSON.stringify(initialParameters))

	/*
		Comita as alterações de parâmetros
	*/
	commit(`${operationId}Parametros`, parameters)

	/*
		ReFetch da operação
		__TODO__ uma forma padrão de não executar
		esse fetch em momentos onde isso não será necessário
		pode ser uma boa ideia
	*/
	dispatch(operationId)

}

/*
	Cria a ação com o operationId e moduleName
	retorna um objeto com a chave certa para a action
	do vuex, proto para um merge: actions: { ...estaAction }
*/
export default ({ operationId, moduleName, initialParameters }) => ({
	[`${operationId}Parametros`]: createAction({ operationId, moduleName, initialParameters })
})