/*
	Factory de request interceptor
	que captura o token do nosso usuário
	e modifica o header de autorização
	da nossa aplicação
*/
export default store => request => {
	/*
		Autorização
	*/
	let token = store.state.oidc?.user?.access_token
	if(token) 
		request.headers.Authorization = `Bearer ${token}`

	/*
		Por padrão application/json
	*/
	if(request.body && !request.headers["Content-Type"]) {
		request.headers["Content-Type"] = "application/json";
		request.headers["Access-Control-Allow-Origin"] = "*";
	}
		
	return request
}
