/*
	Fecha o Popper Global
*/
const CLOSE_POPPER_DURATION = 1000
export default ({ commit }) => {
	let removeComponentTimeout = setTimeout( () => {
		commit("clearPopper")
	}, CLOSE_POPPER_DURATION)
	commit("closePopper", { removeComponentTimeout })
}

