import { oidc } from "../plugins/oidc"

/*
	Action para informar à nossa store
	que nosso swagger está pronto para uso
*/
export default async ({ commit, dispatch }, { user }) => {


	//console.log("Atualizando User", user)
	commit("oidcUpdateUser", { user })

	/*
		Se não temos user
	*/
	if(user === null) {	
		/*
			apagamos qualquer registro incompleto 
			de tentativa de login
		*/
		let userManager = await oidc()
		userManager.clearStaleState()

		/*
			enviamos o usuário para o login
		*/
		console.log("Tentando sign in callback")
		dispatch("oidcSignInCallback")
	}

}
