import actionSearch from "./actionSearch"
import actionParametros from "./actionParametros"
import mutationIsLoading from "./mutationIsLoading"
import mutationParameters from "./mutationParametros"
import mutationUpdateResult from "./mutationUpdateResult"
import actionResetState from "../Shared/actionResetState"
import mutationResetState from "../Shared/mutationResetState"

/*
	Faz uso da nossa estrutura padronizada 
	do swagger e aceita as informações arbitrárias
	que escolhemos ao definir parâmetros de busca,
	ids de operações etc.
	Resulta em um módulo vuex
	https://vuex.vuejs.org/guide/modules.html

*/

const createSwaggerSearchModule = ({ moduleName, operationId, parameters={}, getters={} }) => { 

	/*
		Obrigatório
	*/
	if(!moduleName || !operationId) throw new Error('moduleName e operationId são requeridos')

	/*
		Default state
		loading -> trocado quando chamamos a operação remotamente
		result ->  o que o server responde para a operação
		parameters -> parâmetros de busca da operação (seguir modelo do swagger)
	*/
	let state = {
		loading: false,
		result: {
			"numeroDePaginas": 0,
			"registrosPorPagina": 0,
			"totalDeRegistros": 0,
			"pagina": 0,
			"registrosNaPagina": 0,
			"registros": [ ], // array de resultados
		},
		parameters,
	}


	/*
		Cópia profunda do state inicial
	*/
	let initialState = JSON.parse(JSON.stringify(state))

	/*
		Cópia do estado inicial dos parâmetros
	*/
	let initialParameters = initialState.parameters

	/*
		Actions 
		A busca terá o mesmo nome da operação
	*/
	let actions = {
		...actionSearch({ operationId, moduleName }),
		...actionParametros({ operationId, moduleName, initialParameters }),
		...actionResetState({ operationId, moduleName, initialState })
	}

	/*
		Mutations
	*/
	let mutations = {
		...mutationIsLoading({ operationId, moduleName }),
		...mutationUpdateResult({ operationId, moduleName }),
		...mutationParameters({ operationId, moduleName }),
		...mutationResetState({ operationId, moduleName }),
		
	}

	return {
		state,
		actions,
		mutations,
		getters
	}

}

export default createSwaggerSearchModule