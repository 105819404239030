import router from "../../router"
import { oidc } from "../plugins/oidc"

/*
	SignIn Redirect Callback Action
*/

export default async ({ state, dispatch }) => {

	/*
		Tentando coletar o usuário com o redirect callback 
	*/
	try {

		let userManager = await oidc()

		let user = await userManager.signinRedirectCallback()
		/*
			Nesse momento recebemos o Redirect
			É esperado que o user não seja "null",
			caso contrário por qual motivo teria o
			login funcionado? 
			Se esse cenário surgir no futuro,
			é aqui que vamos alterar alguma coisa
		*/
		dispatch("oidcUpdateUser", { user })
		/*
			Redirect
			(mandamos o pathname pelo state)
		*/
		let { pathname = "/" } = user.state
		return router.push(pathname)
	}catch( error ){
		/*
			Não temos como coletar o state aqui
			Podemos estar em uma página comum sem as query
			strings de login
		*/
		if(error.message === "No state in response")
			return dispatch("oidcSignIn")
		/*
			O state da storage não bate com o do server
			(podemos resolver isso depois, mas é possível usar a app)
		*/
		if(error.message === "No matching state found in storage")
			return router.push("/")
		console.log("Problema com o redirect callback", error)
		console.log(state.oidc.user)
	}

}
	
