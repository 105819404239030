import logOperationId from "../logger"

/*
	Define se estamos ou não carregando,
	baseado no id da operação e uid do registro
*/
const createMutation = ({ initialUidState, operationId }) => (state, { uid, loading = true }) => {
	/*
		Se não temos esse uid ainda, usamos um state inicial
	*/
	state.uids[uid] ??= JSON.parse(JSON.stringify(initialUidState))
	/*
		Setando o "loading"
	*/
	state.uids[uid].loading = loading

	// logOperationId(`isLoading${operationId}`, { uid, loading });
}


/*
	Cria a mutação com o operationId e moduleName
	retorna um objeto com a chave certa para a mutation
	do vuex, proto para um merge: mutation: { ...estaMutation }
*/
export default ({ operationId, moduleName, initialUidState }) => {
	return {
	  [`isLoading${operationId}`]: createMutation({ operationId, moduleName, initialUidState })
	};
  }
  