import actionList from "./actionList"
import mutationIsLoading from "./mutationIsLoading"
import mutationUpdateResult from "./mutationUpdateResult"

/*
	Faz uso da nossa estrutura padronizada 
	do swagger e aceita as informações arbitrárias
	que escolhemos ao definir parâmetros de busca,
	ids de operações etc.
	Resulta em um módulo vuex
	https://vuex.vuejs.org/guide/modules.html

*/

const createSwaggerListModule = ({ moduleName, operationId, getters={} }) => { 

	/*
		Obrigatório
	*/
	if(!moduleName || !operationId) throw new Error('moduleName e operationId são requeridos')

	/*
		Default state
		loading -> trocado quando chamamos a operação remotamente
		result ->  array com a resposta conforme operação no swagger
		error -> informações sobre erros ao tentar executar a operação
	*/
	let state = {
		loading: false,
		result: [],
		error: undefined,
	}

	
	/*
		Actions 
		A busca terá o mesmo nome da operação
	*/
	let actions = {
		...actionList({ operationId, moduleName }),
		
	}

	/*
		Mutations
	*/
	let mutations = {
		...mutationIsLoading({ operationId, moduleName }),
		...mutationUpdateResult({ operationId, moduleName }),
		
	}

	return {
		state,
		actions,
		mutations,
		getters
	}

}

export default createSwaggerListModule