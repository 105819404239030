/*
	Nossa integração com o swagger
*/
import SwaggerClient from 'swagger-client'
import spec from '../../fixtures/swagger.json'

/*
	Exportando o plugin
*/
export default store => {

	new SwaggerClient({ spec }).then ( swagger => {

		/*
			Revertendo os paths em operationIds
			para rápida identificação das características
			da operação durante o uso
			(EX: descobrir se a operação espera multipart/form-data)
		*/
		let pathsObjectArr = Object.entries(spec.paths)
		let methodsArr = pathsObjectArr.flatMap( ([ path, pathValue ]) => 
			Object.entries(pathValue).map( ([ method, methodValue ]) => 
				methodValue.operationId 
				? 	{ 
						[methodValue.operationId ]: {
							...methodValue,
							method,
							path
						}
					}
				: 	{}
			)
		)
		
		/*
			Com as operações já mapeadas pelo identificador único, 
			basta concatenar as propriedades
		*/
		let operations = methodsArr.reduce( (acc, next) => ({...next, ...acc}), {})
		
		/*
			Informando a inicialização do swagger
			e disponibilizando o client pela store
		*/
		store.dispatch("swaggerIsReady", { client: swagger, operations })
	})
}