/*
	Aqui registramos o mapa atual do site,
	Bem como detalhes que precisamos para
	Dinamicamente definir quais são as rotas,
	links do menu e demais extruturas so site
	que dependem de como ele se estrutura em "views"
	
	Cada sessão do site poderá conter um Título, ícone
	path (url), views internas (sub sessões) e uma view
	que é também o nome do componente de View usado em
	nosso router

	Para não exibir qualquer sessão no menu, basta
	marcar hideFromMenu=true em suas propriedades

	IMPORTANTE:
		O valor dos ícones é a string que identifica
		um componente globalmente registrado
		Temos um ponto padrão para registrar componentes
		globalmente: /src/plugins/global/registerGlobalComponents

*/
/*
	"admin",				
	"chefe-atendente",		
	"analista-atendente",	
	"chefe-minuta",			
	"analista-minuta",		
	"chefe-lavratura",		
	"analista-lavratura",	
	"chefe-pagamento",		
	"analista-pagamento",	
	"advogado",				()
	"parte-interessada",  	(aparece com token)
*/
import { 
	ROLES,
	ALL_ROLES,
	INTERNAL_ROLES,
	EXTERNAL_ROLES,
	SPECIAL_ROLES
} from "../plugins/roles"

const sitemap = [

	/*
		Home
	*/
	{ 
		title: "Página Inicial", 
		path: "/",
		hideFromMenu: true,
		hideFromRouter: true
	},
	/*
		Main pages
	*/
	{ 
		title: "Meu Perfil",
		icon: 'user-filled',
		allowedRoles: [
			ROLES.CHEFE,
			ROLES.ANALISTA,
			ROLES.CHEFE_ATENDENTE,
			ROLES.ANALISTA_ATENDENTE,
			ROLES.CHEFE_MINUTA,
			ROLES.ANALISTA_MINUTA,
			ROLES.CHEFE_LAVRATURA,
			ROLES.ANALISTA_LAVRATURA,
			ROLES.CHEFE_PAGAMENTO,
			ROLES.ANALISTA_PAGAMENTO,
			ROLES.ADVOGADO,
			ROLES.PARTE_INTERESSADA,
			ROLES.ADMIN,
			ROLES.GODMODE
		],
		views: [
			{ 
				title: "Conta", 
				view: "UserProfileView",
				path: "/user-account-settings",
				icon: "operation",
			},
			// { 
			// 	title: "Sair", 
			// 	path: "/signout",
			// 	icon: "turn-off",
			// },
		], 
	},
	{ 
		title: "Atendimento",
		icon: "service",
		allowedRoles: [
			ROLES.CHEFE,
			ROLES.ANALISTA,
			ROLES.CHEFE_ATENDENTE,
			ROLES.ANALISTA_ATENDENTE,
			ROLES.CHEFE_MINUTA,
			ROLES.ANALISTA_MINUTA,
			ROLES.CHEFE_LAVRATURA,
			ROLES.ANALISTA_LAVRATURA,
			ROLES.CHEFE_PAGAMENTO,
			ROLES.ANALISTA_PAGAMENTO,
			ROLES.ADMIN,
			ROLES.GODMODE
		],
		views: [
			{ title: "Atendimentos", icon: 'ticket', view: "Atendimentos", path: "/atendimentos" },
		]
	},
    {
		title: "Meus Processos",
		icon: "tickets",
		allowedRoles: [
			ROLES.CHEFE,
			ROLES.ANALISTA,
			ROLES.CHEFE_ATENDENTE,
			ROLES.ANALISTA_ATENDENTE,
			ROLES.CHEFE_MINUTA,
			ROLES.ANALISTA_MINUTA,
			ROLES.CHEFE_LAVRATURA,
			ROLES.ANALISTA_LAVRATURA,
			ROLES.CHEFE_PAGAMENTO,
			ROLES.ANALISTA_PAGAMENTO,
			ROLES.ADVOGADO,
			ROLES.PARTE_INTERESSADA,
			ROLES.ADMIN,
			ROLES.GODMODE
		],
		views: [
			{ 
				title: "Abrir novo processo",
				icon: "document-add",
				view: "NovaSolicitacao", 
				path: "/nova-solicitacao"
			},
			{ 
				title: "Processos em andamento",
				icon: "files",
				view: "ProcessosAbertos",
				path: "/processos-abertos"
			},
		]
	},
	{ 
		title: "Dashboards",
		icon: "data-analysis",
		allowedRoles: [ 
			ROLES.CHEFE,
			ROLES.CHEFE_ATENDENTE,
			ROLES.CHEFE_LAVRATURA,
			ROLES.CHEFE_MINUTA,
			ROLES.CHEFE_PAGAMENTO,
			ROLES.ADMIN,
			ROLES.GODMODE
		],
		views: [
			{ title: "Consolidado", icon: 'data-board', view: "DashboardViews", path: "/dashboard-consolidado" },
		]
	},
	{ 
		title: "Interno",
		icon: "files",
		allowedRoles: [
			ROLES.CHEFE,
			ROLES.ANALISTA,
			ROLES.CHEFE_ATENDENTE,
			ROLES.ANALISTA_ATENDENTE,
			ROLES.CHEFE_MINUTA,
			ROLES.ANALISTA_MINUTA,
			ROLES.CHEFE_LAVRATURA,
			ROLES.ANALISTA_LAVRATURA,
			ROLES.CHEFE_PAGAMENTO,
			ROLES.ANALISTA_PAGAMENTO,
			ROLES.ADMIN,
			ROLES.GODMODE
		],
		views: [
			{ title: "Ausências", icon: 'tickets', view: "AusenciasView", path: "/ausencias" },
		]
	},
	{ 
		title: "Cadastros",
		icon: "notebook",
		allowedRoles: [ 
			ROLES.ADMIN,
			ROLES.GODMODE
		],
		views: [
			{ title: "Usuários", icon: 'user-filled', view: "Usuarios", path: "/usuarios" },
			{ title: "Setor", icon: 'office-building', view: "Setor", path: "/Setor" },
			{ title: "Colaboradores", icon: 'user', view: "ColaboradoresView", path: "/colaboradores" },
			{ title: "Tipos de Processo", icon: 'files', view: "TipoDeProcesso", path: "/tipo-processo" },
			{ title: "Instrução de Preenchimento", icon: 'edit', view: "InstrucaoDePreenchimentoView", path: "/instrucao-preenchimento" },
		]
	},
    {
		title: "Configuração",
		icon: "setting",
		allowedRoles: [
			ROLES.GODMODE,
		],
		views: [
			{ title: "Workflows", icon: 'management', view: "MapaProcessualList", path: "/mapa-processual" },
			{ title: "Tipo de Assunto", icon: 'data-line', view: "TipoDeAssuntoView", path: "/tipo-de-assunto" },
		]
	},
	{ 
		title: "Sair", 
		path: "/signout",
		icon: "turn-off",
		allowedRoles: [
			ROLES.CHEFE,
			ROLES.ANALISTA,
			ROLES.CHEFE_ATENDENTE,
			ROLES.ANALISTA_ATENDENTE,
			ROLES.CHEFE_MINUTA,
			ROLES.ANALISTA_MINUTA,
			ROLES.CHEFE_LAVRATURA,
			ROLES.ANALISTA_LAVRATURA,
			ROLES.CHEFE_PAGAMENTO,
			ROLES.ANALISTA_PAGAMENTO,
			ROLES.ADVOGADO,
			ROLES.PARTE_INTERESSADA,
			ROLES.ADMIN,
			ROLES.GODMODE
		],
	},	

	/*
		Internal
	*/
    { 
		title: "Novo Perfil",
		hideFromMenu: true,
		view: "NovoPerfil", 
		path: "/novo-perfil"
	},
	{ 
		title: "Nova Solicitação",
		hideFromMenu: true,
		view: "NovaSolicitacao", 
		path: "/nova-solicitacao"
	},
	{ 
		title: "Solicitação",
		hideFromMenu: true,
		view: "Solicitacao", 
		path: "/solicitacao/:uid"
	},
	{ 
		title: "Histórico da Solicitação",
		hideFromMenu: true,
		view: "HistoricoDeSolicitacao", 
		path: "/solicitacao/:uid/historico"
	},
	{ 
		title: "Atendimento",
		hideFromMenu: true,
		view: "Atendimento", 
		path: "/atendimento/:uid"
	},
	{ 
		title: "Histórico de Atendimento",
		hideFromMenu: true,
		view: "HistoricoDeAtendimento", 
		path: "/atendimento/:uid/historico"
	},
	{ 
		title: "Workflow - Detalhes",
		hideFromMenu: true,
		view: "MapaProcessualDetalhes", 
		path: "/mapa-processual/:uid"
	},
	{ 
		title: "Ação - Detalhes",
		hideFromMenu: true,
		view: "TrilhoProcessualDetailView", 
		path: "/trilho-processual-detalhes/:uid/:mapaProcessualUidParams"
	},
]

export default sitemap
