import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Maska from 'maska'
import registerGlobalComponents from './plugins/global/registerGlobalComponents'
import { Buffer as BufferPolyfill } from 'buffer'
import permissionDirective from './plugins/directives/permission.js';

/*
	Tema padrão
*/
import './plugins/skins/public/sucessiva/theme.css'

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'

globalThis.Buffer = BufferPolyfill

const app = createApp(App)

app.directive('permission', permissionDirective);
/*
	@popperjs/core @tiptap/extension-highlight @tiptap/extension-placeholder @tiptap/extension-text-align @tiptap/extension-underline @tiptap/starter-kit @tiptap/vue-3 bezier-easing core-js date-fns debounce element-plus file-saver iscpf isemail maska oidc-client swagger-client vue vue-router vuex vuex-oidc
	-D babel-eslint babel-plugin-import  eslint eslint-plugin-vue nodemon postcss-custom-media @vitejs/plugin-vue vite sass

	removendo: element-theme element-theme-chalk element-themex
*/

/*
	Injetando diretriz de máscaras
*/
app.use(Maska)

app.use(dayjs);

dayjs().locale('pt-br');

registerGlobalComponents(app);

app.use(store).use(router).mount('#app')
