/*
	Atualiza o resultado da operação
*/
const createMutation = () => (state, { result, error } = {}) => {
	state.result 	= result
	state.error 	= error
}

/*
	Cria a mutação com o operationId e moduleName
	retorna um objeto com a chave certa para a mutation
	do vuex, proto para um merge: mutation: { ...estaMutation }
*/
export default ({ operationId, moduleName }) => ({
	[`update${moduleName}`]: createMutation({ operationId, moduleName })
})