<template>
	<el-sub-menu v-if="submenu && isAuthorized"
		:index="indexPrefix"
	>
		<template #title>
			<el-icon>
				<component :is="icon" />
			</el-icon>
			<span>{{ title }}</span>
		</template>
		<el-menu-item v-for="(item, index) in submenu" 
			:index="`${indexPrefix}-${index}`" 
			:key="index"
			@click="go(item.path)"
		>
			<template #title>
				<el-icon>
					<component :is="item.icon" />
				</el-icon>
				<router-link :to="item.path">
					<span>{{ item.title }}</span>
				</router-link>
			</template>
		</el-menu-item>
	</el-sub-menu>
	<el-menu-item v-else-if="isAuthorized"
		:index="indexPrefix" 
		@click="go(path)"
	>
		<template #title>
			<el-icon>
				<component :is="icon" />
			</el-icon>
			<span>{{ title }}</span>
		</template>
	</el-menu-item>
</template>

<script>
import { computed, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import { isEmpty } from "../plugins/props";
import { 
	ElSubMenu,
	ElIcon,
	ElMenuItem,
 } from "element-plus";

export default {
	name: 'SideSubMenuVue',
	components: {
		ElSubMenu,
		ElIcon,
		ElMenuItem,
	},
	props: {
		menu: {
			type: Object,
			required: true,
			default: () => ({
				title: String,
				icon: Object,
				submenu: Array,
				path: String,
				indexPrefix: String,
			})
		},
	},
	setup(props) {
		const { allowedRoles, title, icon, submenu, path, indexPrefix } = toRefs(props.menu);

		const store = useStore();
		const oidc = computed(() => store.state.oidc);

		const roles = computed(() => [].concat(oidc.value.user?.profile?.role || []));

		const isAuthorized = computed(() => !isEmpty(allowedRoles?.value) ? allowedRoles.value.some(item => roles.value?.includes(item) || roles.value?.some(role => role.startsWith(item))) : false);

		const router = useRouter();

		const go = async path => {
			switch (path) {
				case '/signout':
					await store.dispatch("oidcSignOut");
					break;
				case '/signin-callback-oidc':
					break;
				default:
					router.push(path);
					break;
			}
		}

		watch(oidc, () => {
			const newRoles = [].concat(oidc.value.user?.profile?.role || []);
			roles.value = newRoles;
			isAuthorized.value = !isEmpty(allowedRoles?.value) ? allowedRoles.value.some(item => newRoles.includes(item) || newRoles.some(role => role.startsWith(item))) : false;
		});

		return {
			title,
			icon,
			submenu,
			path,
			indexPrefix,
			go,
			isAuthorized,
		};
	},
}
</script>

<style scoped>
</style>
