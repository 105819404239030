import closeMenu from "./closeMenu"
import openMenu from "./openMenu"
import toggleMenu from "./toggleMenu"
import oidcUpdateUser from "./oidcUpdateUser"
import oidcUserManager from "./oidcUserManager"
import swaggerIsReady from "./swaggerIsReady"
import isLoadingColaboradores from "./isLoadingPesquisarColaboradores"
import updateMetaSolicitacao from "./updateMetaSolicitacao"
import isLoadingMinhasSolicitacoes from "./isLoadingMinhasSolicitacoes"
import isLoadingBaixarTodosAnexosProcesso from "./isLoadingBaixarTodosAnexosProcesso"
import updateBaixarTodosAnexosProcesso from "./updateBaixarTodosAnexosProcesso"
import updateMinhasSolicitacoes from "./updateMinhasSolicitacoes"
import MinhasSolicitacoesParametros from "./MinhasSolicitacoesParametros"
import updatePesquisarColaboradores from "./updatePesquisarColaboradores"
import isLoadingPesquisarColaboradores from "./isLoadingPesquisarColaboradores"
import isLoadingPesquisarInstrucoes from "./isLoadingPesquisarInstrucoes"
import updatePesquisarInstrucoes from "./updatePesquisarInstrucoes"
import isLoadingObterInstrucao from "./isLoadingObterInstrucao"
import updateObterInstrucao from "./updateObterInstrucao"
import PesquisarInstrucoesParametros from "./PesquisarInstrucoesParametros"
import isLoadingEnvioInstrucao from "./isLoadingEnvioInstrucao"
import updateAtualizarInstrucao from "./updateAtualizarInstrucao"
import isLoadingPesquisarTipoSolicitacao from "./isLoadingPesquisarTipoSolicitacao"
import updatePesquisarTipoSolicitacao from "./updatePesquisarTipoSolicitacao"
import PesquisarTipoSolicitacaoParametros from "./PesquisarTipoSolicitacaoParametros"
import updateConsultarProcessos from "./updateConsultarProcessos"
import ConsultarProcessosParametros from "./ConsultarProcessosParametros"
import isLoadingConsultarProcessos from "./isLoadingConsultarProcessos"
import isLoadingEnvioInserirColaborador from "./isLoadingEnvioInserirColaborador"
import configureChart from "./configureChart"
import closeChartConfigurator from "./closeChartConfigurator"
import setReducedMotion from "./setReducedMotion"
import showPopper from "./showPopper"
import closePopper from "./closePopper"
import clearPopper from "./clearPopper"
/*
Exportando as mutations que usaremos
*/
export default {
	openMenu,
	closeMenu,
	toggleMenu,
	oidcUpdateUser,
	oidcUserManager,
	swaggerIsReady,
	isLoadingColaboradores,
	updateMetaSolicitacao,
	isLoadingMinhasSolicitacoes,
	isLoadingBaixarTodosAnexosProcesso,
	updateBaixarTodosAnexosProcesso,
	updateMinhasSolicitacoes,
	MinhasSolicitacoesParametros,
	updatePesquisarColaboradores,
	isLoadingPesquisarColaboradores,
	isLoadingPesquisarInstrucoes,
	updatePesquisarInstrucoes,
	isLoadingObterInstrucao,
	updateObterInstrucao,
	PesquisarInstrucoesParametros,
	isLoadingEnvioInstrucao,
	updateAtualizarInstrucao,
	isLoadingPesquisarTipoSolicitacao,
	updatePesquisarTipoSolicitacao,
	PesquisarTipoSolicitacaoParametros,
	updateConsultarProcessos,
	ConsultarProcessosParametros,
	isLoadingConsultarProcessos,
	isLoadingEnvioInserirColaborador,
	configureChart,
	closeChartConfigurator,
	setReducedMotion,
	showPopper,
	closePopper,
	clearPopper,
}
