/*
	Limpa o state dessa operação
	passando uid, limpamos apenas na sua key
*/
const createMutation = ( createOptions = { initialState:{} }) => (state, { initialState, uid } = {}) => 
	Object.entries(initialState || createOptions.initialState).map( ([key, value]) => 
		( uid ? state.uids[uid] : state )[key] = value
	)

/*
	Cria a mutação com o operationId e moduleName
	retorna um objeto com a chave certa para a mutation
	do vuex, proto para um merge: mutation: { ...estaMutation }
*/
export default ({ moduleName, initialState }) => ({
	[`clear${moduleName}`]: createMutation({ moduleName, initialState })
})