<template>
	<el-menu 
		default-active="2-1" 
		:class="state.cls"
		:collapse="state.isCollapse"
		@open="handleOpen" 
		@close="handleClose"
		background-color="var(--el-color-primary)" 
		text-color="var(--el-color-primary-light-8)"
		active-text-color="var(--el-color-white)">
		<SideSubMenuVue v-for="(submenu, index) in menu"
			:menu="submenu" 
			:key="index"
		/>
	</el-menu>
</template>

<script>
import { computed, reactive, onMounted, watchEffect } from 'vue';
import { useStore } from "vuex";
import SideSubMenuVue from "./SideSubMenu.vue";
import siteMapMenu from '../sitemap/siteMapMenu';
import sitemap from "../sitemap/index"
import { ElMenu, } from 'element-plus';

export default {
	components: {
		ElMenu,
		SideSubMenuVue,
	},
	setup() {
		const store = useStore();
		const oidc = computed(() => store.state.oidc);
		const menu = computed(() => store.state.menu);
		const isReady = computed(() => store.getters.appIsReady);

		const state = reactive({
			mounted: false,
			cls: "sidemenu  before-mount",
			isCollapse: false
		});

		const handleOpen = () => {
			console.log('handleOpen');
		};

		const handleClose = () => {
			console.log('handleClose');
		};

		onMounted(() => {
			setTimeout(() => state.cls = "sidemenu", 450);
		});

		watchEffect(() => {
			if (isReady.value && oidc.value) {
				store.state.menu = siteMapMenu(sitemap, '', oidc.value);
			}
		});

		return {
			menu,
			state,
			handleOpen,
			handleClose
		};
	},
};

</script>

<style>
/*
		Overrides que não temos acesso pelos argumentos do componente
	*/
.sidemenu {
	border: none !important;
}

.sidemenu a,
.sidemenu a:hover {
	color: inherit;
	text-decoration: none;
	width: 100%;
	height: 100%;
}

.sidemenu .el-submenu__title:hover,
.sidemenu .el-menu-item:hover,
.sidemenu .el_submenu:hover {
	background-color: #909399 !important;
}

/*
		Tirando a animação inicial da cor do element-plus
		para a cor do nosso tema
	*/
.before-mount * {
	transition:
		border-color 0s,
		background-color 0s,
		color 0s;
}
</style>
